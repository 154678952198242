.info-icon {
  display: block;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  margin-right: 0.5rem;
  background: url(../images/info-icon.svg) bottom / contain no-repeat;
  cursor: help;
  outline: none;
}
