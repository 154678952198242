ul.list-select {
  list-style: none;
  margin: 0;
  padding: var(--list-select-padding);
  border: var(--list-select-border);
  border-radius: var(--list-select-border-radius);
  background: var(--list-select-background);
  min-height: var(--list-select-min-height);
  height: var(--list-select-height);
  overflow-y: auto;
}

/* Each list item */
ul.list-select li {
  padding: var(--list-select-item-padding);
  border: var(--list-select-item-border);
  cursor: var(--list-select-item-cursor);
  font: inherit;
  border-radius: var(--list-select-item-border-radius);
  margin: var(--list-select-item-margin);
}

/* Visual feedback for a selected item */
ul.list-select li.selected {
  background: var(--list-select-item-selected-bg);
}

/* Visual feedback for an item being dragged */
ul.list-select li.dragging {
  opacity: var(--list-select-item-dragging-opacity);
}

/* Optional: add a hover effect */
ul.list-select li:hover {
  background: var(--list-select-item-hover-bg);
}

ul.list-select li::before {
  color: var(--list-select-item-before-color);
  content: '≡';
  font-size: var(--list-select-item-before-font-size);
  margin-right: var(--list-select-item-before-margin-right);
}

.list-remove-button {
  margin-top: var(--list-remove-button-margin-top);
}


.list-select-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.list-select-keyboard-active {
  outline: var(--list-select-keyboard-outline);
  background: var(--list-select-keyboard-active-bg);
}