.email-manage {
  display: flex;
  justify-content: space-between;
}

.email-manage-heading {
  margin-top: 3rem;
  font-size: 1.8rem;
}

.email-manage-form-temp-link {
  max-width: 40rem;
  line-height: 1.6;
}

.email-manage-preview {
  flex: 1 1 1;
}

.email-manage-preview-start {
  display: flex;
  justify-content: center;
  width: 50%;
}

.email-manage-preview-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 67rem;
}

.email-manage-preview-start-button {
  position: relative;
  width: 29rem;
  height: 34.4rem;
  border: 0.1rem solid #adadad;
  border-radius: var(--borderRadius);
  font-weight: bold;
  color: #818181;
  background: transparent url(../images/email-builder.svg) center  12px / 265px no-repeat;
  cursor: pointer;
}

.email-manage-preview-start-button-text {
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  width: 100%;
  font-size: 1.9rem;
  color: #f09244;
}

.email-manage-preview-start-button:--focus-hover .email-manage-preview-start-button-text {
  color: #e76f0c;
}

.email-manage-preview-header-button {
  margin: 0 0 1rem;
}

.email-manage-preview-test {
  display: flex;
  justify-content: center;
  margin: 2.5rem 0 3rem;
}

.email-manage-preview-info {
  line-height: 1.6;
  text-align: center;
}

.email-manage-warning-icon {
  display: inline-block;
  position: relative;
  top: 0.1rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  margin-left: 0.6rem;
  background: url(../images/email/warning.svg#red) center / contain no-repeat;
  cursor: pointer;
}

.email-manage-warning-popup {
  display: none;
  position: absolute;
  top: 2.7rem;
  width: 40rem;
  padding: 2rem;
  border: 1px solid #cecece;
  border-radius: 0.4rem;
  box-shadow: 0 0 1rem #b1b1b1;
  line-height: 1.6;
  color: #484848;
  background: #fff;
}

.email-manage-warning-popup[aria-hidden='false'] {
  display: block;
}

.email-content-container {
  display: flex;
  justify-content: center;
  width: 66.6rem;
  height: 55rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 3rem;
  border-radius: 0.4rem;
}

.email-preview-image {
  padding-bottom: 3rem;
}

.filter-time-select {
  margin-left: 1rem;
}

.email-consent-message {
  font-weight: bold;
  line-height: 1.6;
}

.email-consent-message.mod-spacing {
  margin: 2rem 0 0;
}

@media (width < 1200px) {
  .email-manage {
    flex-direction: column;
  }

  .email-manage-preview {
    flex: 1 0 auto;
  }
}

.email-validation-warning-block {
  position: relative;
  padding: 1.8rem 5rem 0.1rem;
  color: #9e780f;
  background: #fffac4;
}

.email-validation-warning-icon {
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  margin-right: 0.3rem;
  background: url(../images/email/warning.svg#darkyellow) center / contain no-repeat;
}

.email-validation-error-list {
  padding-left: 1.4rem;
}

.email-validation-error-list-item:not(:last-child) {
  padding: 0.3rem 0;
}

.email-validation-button-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

@media (width < 700px) {
  .email-validation-warning-block {
    padding: 1.8rem;
  }

  .email-validation-warning-icon {
    display: none;
  }
}

/* Prevent any dragging of any images */
/* stylelint-disable unknownProperties, selector-max-type, property-no-vendor-prefix, property-no-unknown */
:global(img) {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
/* stylelint-enable selector-max-type, property-no-vendor-prefix, property-no-unknown  */
