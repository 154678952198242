.relates-to-list {
  margin: -0.4rem 0 0 -2.2rem;
  line-height: 1.5;
}

.private-task-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  margin-top: -1.6rem;
  margin-left: -1rem;
  background: url(../images/key.svg) center / contain no-repeat;
}

.private-task-icon-outer {
  width: 3rem;
  border: none;
  background: transparent;
  cursor: help;
  outline: none;
}
