/**
 * Main sections styling
 */
.home-content-container {
  display: flex;
}

.home-content-right,
.home-content-left {
  box-sizing: border-box;
}

.home-content-left {
  display: flex;
  flex: auto;
  flex-direction: column;
  max-width: 38rem;
  width: 100%;
  gap: 2rem;
}

.home-content-right {
  flex: 1 1;
  min-width: 30rem;
  font-size: 1.4rem;
  margin-left: 4rem;
}

@media (max-width: 890px) {
  .home-content-container {
    flex-flow: column nowrap;
  }

  .home-content-right {
    margin-left: 0;
  }

  .home-content-left {
    max-width: none;
  }
}

/**
 * Pagination / Filters toolbar
 */

.home-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-toolbar .PagingRow {
  margin-right: 2rem;
}

.home-toolbar .filter-container {
  margin-left: auto;
}

@media (max-width: 890px) {
  .home-toolbar {
    align-items: initial;
    flex-direction: column-reverse;
  }

  .home-toolbar .PagingRow {
    margin-right: 0;
  }

  .home-toolbar .filter-container {
    margin-left: 0;
  }
}

/**
 * Misc
 */

.video-in-modal {
  max-width: 100%;
  max-height: calc(100% - 4rem);
}

/**
 * Sidebar section header
 */
.ptiSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-self: stretch;
  margin-bottom: 16px;
}

.ptiSectionHeader h2 {
  color: #333;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  margin: 0;
  padding: 0;
}

/**
 * Folder / tag list wrapper
 * Note: background style adds overflow 'shadow' effect
 */
#pnlCustomers,
#pnlMyTags,
#pnlSharedTags {
  overflow-y: auto;
  max-height: 40vh;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

@media (max-width: 890px) {
  #pnlCustomers,
  #pnlMyTags,
  #pnlSharedTags {
    max-height: 20vh;
  }
}
/**
 * Folder / tag list / list item styling
 */
.ulHomeFolder,
.ulHomeTag {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.liHomeFolder,
.liHomeTag {
  position: relative;
  white-space: nowrap;
  width: 100%;
}

/**
 * Nested Folder Wrapper
 */
.liHomeFolder > .ulHomeFolder {
  padding-left: 18px;
  margin-left: 18px;
  position: relative;
}

/**
 * Nesting Indicator Line
 */
.liHomeFolder > .ulHomeFolder::before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * Folder / tag link styling
 */

.liHomeFolder a,
.liHomeTag a {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.liHomeFolder span + span,
.liHomeTag span + span {
  margin-left: 6px;
}

.liHomeFolder a:hover,
.liHomeFolder a:focus-visible,
.liHomeTag a:hover,
.liHomeTag a:focus-visible {
  background-color: rgba(0, 0, 0, 0.02);
}

.liHomeFolder.selected > a {
  background-color: rgba(31, 106, 190, 0.05);
}

/**
 * Folder / tag SVG icons
 */
.spanFolderIcon {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgNEg5TDEyIDdIMTlDMTkuNTMwNCA3IDIwLjAzOTEgNy4yMTA3MSAyMC40MTQyIDcuNTg1NzlDMjAuNzg5MyA3Ljk2MDg2IDIxIDguNDY5NTcgMjEgOVYxN0MyMSAxNy41MzA0IDIwLjc4OTMgMTguMDM5MSAyMC40MTQyIDE4LjQxNDJDMjAuMDM5MSAxOC43ODkzIDE5LjUzMDQgMTkgMTkgMTlINUM0LjQ2OTU3IDE5IDMuOTYwODYgMTguNzg5MyAzLjU4NTc5IDE4LjQxNDJDMy4yMTA3MSAxOC4wMzkxIDMgMTcuNTMwNCAzIDE3VjZDMyA1LjQ2OTU3IDMuMjEwNzEgNC45NjA4NiAzLjU4NTc5IDQuNTg1NzlDMy45NjA4NiA0LjIxMDcxIDQuNDY5NTcgNCA1IDRaIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utb3BhY2l0eT0iMC40NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
  display: inline-block;
  height: 24px;
  width: 24px;
}

.selected > a .spanFolderIcon {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgM0M5LjIxOTggMi45OTk5OCA5LjQzMzQ4IDMuMDcyMzggOS42MDggMy4yMDZMOS43MDggMy4yOTNMMTIuNDE0IDZIMTlDMTkuNzY1MiA1Ljk5OTk2IDIwLjUwMTUgNi4yOTIzMyAyMS4wNTgzIDYuODE3MjhDMjEuNjE1IDcuMzQyMjQgMjEuOTUwMSA4LjA2MDExIDIxLjk5NSA4LjgyNEwyMiA5VjE3QzIyIDE3Ljc2NTIgMjEuNzA3NyAxOC41MDE1IDIxLjE4MjcgMTkuMDU4M0MyMC42NTc4IDE5LjYxNSAxOS45Mzk5IDE5Ljk1MDEgMTkuMTc2IDE5Ljk5NUwxOSAyMEg1QzQuMjM0NzkgMjAgMy40OTg0OSAxOS43MDc3IDIuOTQxNzQgMTkuMTgyN0MyLjM4NDk5IDE4LjY1NzggMi4wNDk4OSAxNy45Mzk5IDIuMDA1IDE3LjE3NkwyIDE3VjZDMS45OTk5NiA1LjIzNDc5IDIuMjkyMzMgNC40OTg0OSAyLjgxNzI4IDMuOTQxNzRDMy4zNDIyNCAzLjM4NDk5IDQuMDYwMTEgMy4wNDk4OSA0LjgyNCAzLjAwNUw1IDNIOVoiIGZpbGw9IiMxRjZBQkUiIGZpbGwtb3BhY2l0eT0iMC44Ii8+Cjwvc3ZnPgo=');
}

.spanTagIcon {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSA3LjVDNi41IDcuNzY1MjIgNi42MDUzNiA4LjAxOTU3IDYuNzkyODkgOC4yMDcxMUM2Ljk4MDQzIDguMzk0NjQgNy4yMzQ3OCA4LjUgNy41IDguNUM3Ljc2NTIyIDguNSA4LjAxOTU3IDguMzk0NjQgOC4yMDcxMSA4LjIwNzExQzguMzk0NjQgOC4wMTk1NyA4LjUgNy43NjUyMiA4LjUgNy41QzguNSA3LjIzNDc4IDguMzk0NjQgNi45ODA0MyA4LjIwNzExIDYuNzkyODlDOC4wMTk1NyA2LjYwNTM2IDcuNzY1MjIgNi41IDcuNSA2LjVDNy4yMzQ3OCA2LjUgNi45ODA0MyA2LjYwNTM2IDYuNzkyODkgNi43OTI4OUM2LjYwNTM2IDYuOTgwNDMgNi41IDcuMjM0NzggNi41IDcuNVoiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1vcGFjaXR5PSIwLjQ1IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMyA2VjExLjE3MkMzLjAwMDExIDExLjcwMjQgMy4yMTA5IDEyLjIxMSAzLjU4NiAxMi41ODZMMTEuMjk2IDIwLjI5NkMxMS43NDggMjAuNzQ3OSAxMi4zNjA5IDIxLjAwMTcgMTMgMjEuMDAxN0MxMy42MzkxIDIxLjAwMTcgMTQuMjUyIDIwLjc0NzkgMTQuNzA0IDIwLjI5NkwyMC4yOTYgMTQuNzA0QzIwLjc0NzkgMTQuMjUyIDIxLjAwMTcgMTMuNjM5MSAyMS4wMDE3IDEzQzIxLjAwMTcgMTIuMzYwOSAyMC43NDc5IDExLjc0OCAyMC4yOTYgMTEuMjk2TDEyLjU4NiAzLjU4NkMxMi4yMTEgMy4yMTA5IDExLjcwMjQgMy4wMDAxMSAxMS4xNzIgM0g2QzUuMjA0MzUgMyA0LjQ0MTI5IDMuMzE2MDcgMy44Nzg2OCAzLjg3ODY4QzMuMzE2MDcgNC40NDEyOSAzIDUuMjA0MzUgMyA2WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW9wYWNpdHk9IjAuNDUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
  display: inline-block;
  height: 24px;
  width: 24px;
}

/**
 * Folder / tag name styling
 */
.spanHomeFolderName,
.spanHomeTagName {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  line-height: normal;
  color: rgba(0, 0, 0, 0.9);
}

.liHomeFolder.selected > a .spanHomeFolderName {
  font-weight: 500;
}

/**
 * Count badge
 */
.spanHomeFolderDocCount,
.spanHomeTagDocCount {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f7f7f7;
  width: auto;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.125rem 0.25rem;
}

.liHomeFolder.selected > a .spanHomeFolderDocCount {
  border: 1px solid rgba(31, 106, 190, 0.2);
  background: rgba(31, 106, 190, 0.05);
  color: rgba(0, 0, 0, 0.9);
}
