@import 'settings.css';

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
}

:root {
  --border: 1px solid #eee;
  --sidePadding: 1.8rem;
}

/* stylelint-disable declaration-no-important */
.modal-container {
  display: inline-flex !important;
  flex-direction: column;
  min-width: 50rem;
  max-width: calc(var(--containerWidth) - 10rem);
  max-height: 90rem;
  border-radius: var(--borderRadius);
  margin: 1rem 0;
  background: #f5f5f5;
}

@media (height < 800px) {
  .modal-container {
    max-height: 95% !important;
  }
}

@media (width < 1300px) {
  .modal-container {
    max-width: 95%;
  }
}

@media (width < 520px) {
  .modal-container {
    min-width: 20rem;
  }
}

/* stylelint-disable-next-line */
#help-modal .modal-container {
  max-width: 85rem;
}

@media all and (-ms-high-contrast: none) {
  /* IE 11 doesn't like to scale SVGs, so just disable the notification circle in IE11 */
  /* stylelint-disable-next-line */
  *::-ms-backdrop,
  .modal-container {
    overflow-y: scroll;
  }
}

.modal-container.is-large {
  width: 90vw;
  max-height: 90vh;
}

.modal-container.is-full-screen {
  width: 98vw;
  height: 98vh;
  max-width: 98vw;
  max-height: 98vh;
}

.modal-container.is-full-screen > .modal-body {
  height: 100%;
  overflow: hidden;
  padding: 0;
}


/* stylelint-enable */

.modal-header {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--border);
}

.modal-header-title {
  padding: 1.2rem 0 1.2rem var(--sidePadding);
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #424242;
}

.modal-close-button {
  align-self: stretch;
  width: 4.8rem;
  min-height: 4rem;
  overflow: hidden;
  padding: 0;
  border: none;
  text-indent: 100%;
  white-space: nowrap;

  /* Use pixels for IE11 compat */
  background: url(../images/cross.svg#grey) center / 10px no-repeat;
  cursor: pointer;
  outline: none;
}

.modal-body {
  overflow: auto;
  padding: 2rem var(--sidePadding);
  font: inherit;
  line-height: 1.5;
}

.modal-body-text {
  line-height: 1.6;
}

.modal-body-text + .modal-body-text {
  margin-top: 1.5rem;
}

.modal-footer {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  padding: 1.1rem var(--sidePadding);
  border-top: var(--border);
}

.modal-cancel-button {
  margin-right: 1.6rem;
}

.modal-primary-button {
  margin-left: 1rem;
}

.modal-body .modal-table {
  max-height: 30vh;
  overflow: auto;
  margin-top: 1.2rem;
}
