:root {
  --errorDark: #c10808;
  --errorLight: #fdd;
  --successDark: #1e8c1e;
  --successLight: #dcf7d0;
  --infoDark: #0f6eb5;
  --infoLight: #c1efff;
}

.page-notice {
  display: flex;
  align-items: center;
  padding: 1.5rem 1.7rem;
  border-left: 0.4rem solid;
  margin-bottom: 4rem;
}

.page-notice.mod-error {
  border-color: var(--errorDark);
  background-color: var(--errorLight);
}

.page-notice.mod-success {
  border-color: var(--successDark);
  background-color: var(--successLight);
}

.page-notice.mod-info {
  border-color: var(--infoDark);
  background-color: var(--infoLight);
}

.page-notice-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 2rem;
  background: center / 1.8rem no-repeat;
  border-width: 0.3rem;
  border-style: solid;
}

.page-notice-icon.mod-error {
  border-color: var(--errorDark);
  background-image: url(../images/cross-red.svg);
}

.page-notice-icon.mod-success {
  border-color: var(--successDark);
  background-image: url(../images/tick.svg);
}

.page-notice-icon.mod-info {
  border-color: var(--infoDark);
  background-image: url(../images/information.svg);
}

.page-notice-message {
  padding: 0;
  margin: 0 0 0.4rem;
  font-size: 1.7rem;
  font-weight: bold;
}

.page-notice-message.mod-error {
  color: var(--errorDark);
}

.page-notice-message.mod-success {
  color: var(--successDark);
}

.page-notice-message.mod-info {
  color: var(--infoDark);
}

.page-notice-link.mod-success {
  color: #2ec100;
}

.page-notice-link.mod-success:--focus-hover {
  color: var(--successDark);
}

.page-notice-link.mod-error {
  color: #ff2821;
}

.page-notice-link.mod-error:--focus-hover {
  color: var(--errorDark);
}

.page-notice-body {
  margin: 0;
}

@media (max-width: 790px) {
  .page-notice-icon {
    display: none;
  }
}
