@import 'settings.css';

.deleted-player-row {
  background: #fbe1e1;
}

.table-topper-left {
  display: flex;
}

.table-topper-select-all {
  margin-right: 1rem;
}

/* stylelint-disable-next-line selector-max-type */
.table-topper-select-all input {
  width: 9.2rem;
}

.player-settings-meta-details {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-size: 1.7rem;
  color: #737373;
}

.player-settings-meta-title {
  margin-bottom: 2rem;
  margin-left: 1rem;
  font-size: 2.7rem;
  font-weight: bold;
  line-height: 1.1;
}

.players-playlist-published-status {
  cursor: help;
}

.valid-selection {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background: url(../images/tick-green.svg) center / contain no-repeat;
}

.invalid-selection {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background: url(../images/cross-red.svg) no-repeat center;
}

.all-rows-warning {
  padding: 10px;
  border: solid 1px #ebccd1;
  border-radius: var(--borderRadius);
  margin: 20px 0;
  line-height: 1.5;
  background: #fcf8e3;
}
