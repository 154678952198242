.sso-form {
  min-width: 55rem;
  padding: 0.5rem;
}

.sso-select {
  width: 20rem;
  height: 15rem;
  margin-right: 5%;
}

.sso-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1.5rem 0;
  margin-bottom: 2.5rem;
}

.col {
  flex-grow: 1;
  padding: 0 1rem;
}

.sso-input {
  display: inline-block;
  margin-left: 1.5rem;
}

.stack {
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: space-evenly;
}

.stack-item {
  align-items: center;
}
