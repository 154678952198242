/* THIS FILE HAS HAD LINTING DISABLED BECAUSE IT'S CAUSING
THE LINTING TO FAIL. NEXT PERSON TO WORK ON IT PLEASE FIX */
/* stylelint-disable */

.table tr.odd-row {
  background-color: #fff !important;
}

.table tr.even-row {
  background-color: #f5f5f5 !important;
}

.table tr.new-row-even {
  background-color: #dff0d8 !important;
}

.table tr.new-row-odd {
  background-color: #e7f4e2 !important;
}

.table tr.sensitive-row-even {
  background-color: #fff8f8;
}

.table tr.sensitive-row-odd {
  background-color: #fffbfb;
}

.table tr.overdue-row-even {
  background-color: #fff8f8;
}

.table tr.overdue-row-odd {
  background-color: #fffbfb;
}

.table tr.highlight-row-even {
  background-color: var(--pageTigerOnlyBG);
}

.table tr.highlight-row-odd {
  background-color: #faf7e8;
}

.table tr.archived-row-even {
  background-color: #fff8f8;
}

.table tr.archived-row-odd {
  background-color: #fffbfb;
}

.table tr.pt-only-row-even {
  border-bottom: 1px solid #e8e3cc;
  background-color: #fcf8e3;
}

.table tr.pt-only-row-odd {
  border-bottom: 1px solid #e8e3cc;
  background-color: #fcf9ea;
}

.table tr.total-row {
  background-color: #ffffff;
  border-top: 0.1rem solid #cacaca;
}

.table tr.no-records-found-row {
  background-color: #fff;
  padding: 30px 0;
  font-size: 1.5rem;
  text-align: center;
  color: #458ed6;
}

.table tr.button-row-even {
  background-color: #f3f3f3;
}

.table tr.button-row-odd {
  background-color: #f6f6f6;
}

.table tr.pt-dev-row-even td {
  background-color: #ffdfdf;
}

.table tr.pt-dev-row-odd td {
  background-color: #ffe7e7;
}

.table tr.spacer-row {
  background-color: #fff;
}

.table tr.actioned-row {
  opacity: 0.5;
}

@media (min-width: 681px) {
  .table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
  }

  .table-caption {
    height: 0;
  }

  .table-head {
    background-color: #ffffff;
    border-bottom: 0.1rem solid #cacaca;
  }

  .table-header {
    text-align: left;
  }

  .table-header.is-hidden {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
  }

  .table-header-button {
    width: 100%;
    border: none;
    margin: 0;
    font: inherit;
    text-align: left;
    background: none;
    cursor: pointer;
  }

  .table-header,
  .table-header-button,
  .table-header.is-sortable {
    padding: 1rem;
  }

  .table-td {
    padding: 1.2rem 1rem;
  }
}

@media (max-width: 680px) {
  .table,
  .table-head,
  .table-header,
  .table-body,
  .table-tr,
  .table-td {
    display: block;
  }

  /* Hide table headers (but not `display: none`, for accessibility) */
  .table-head-row {
    position: absolute;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
  }

  .table-td {
    position: relative;
    padding: 0.5rem 0 0.5rem 50%;
  }

  .table-tr {
    padding: 1rem;
  }

  .table-td::before {
    position: absolute;
    left: 0.2rem; /* Mimic padding */
    width: 45%;
    content: attr(data-name);
    overflow: hidden;
    padding-right: 1rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-td:not([data-name]) {
    padding-left: 0;
  }
}
