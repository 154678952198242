@import 'settings.css';

.icon-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 6rem 0 0;
  list-style: none;
}

.icon-list-item {
  width: calc(20% - 3rem);
  height: 17rem;
  border: var(--border);
  border-radius: 0.7rem;
  margin: 0 1.5rem 7rem;
  box-shadow: 0 1px 3px #dedede;
  text-align: center;
  background: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
}

.icon-list-item.mod-three-across {
  width: calc(33% - 3rem);
}

.icon-list-item.mod-full-width {
  width: calc(100% - 3rem);
  height: 41rem;
}

.icon-list-item.mod-fade {
  opacity: 0.6;
}

.icon-list-item.pt-only {
  border: 1px solid #ffda8a;
  background: #fff9e4;
}

@media (max-width: 930px) {
  .icon-list-item {
    width: calc(25% - 3rem);
  }
}

@media (max-width: 760px) {
  .icon-list-item {
    width: calc(33% - 3rem);
  }
}

@media (max-width: 630px) {
  .icon-list-item {
    width: calc(50% - 3rem);
  }
}

@media (max-width: 390px) {
  .icon-list-item {
    width: 100%;
    margin-right: 0;
  }
}

.icon-list-item:--focus-hover {
  box-shadow: 0 0.3rem 1rem #d4d4d4;
  transform: translateY(-0.15rem);
}

.icon-list-item-link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem 1.5rem;
  border: none;
  color: #f3f3f3;
  background: none;
  cursor: pointer;
}

/* SETTINGS PAGE
================ */
.icon-list-item-image.icon-analytics {
  background-image: url(../images/settings/icon-analytics.svg);
}

.icon-list-item-image.icon-badges {
  background-image: url(../images/settings/icon-badges.svg);
}

.icon-list-item-image.icon-courses {
  background-image: url(../images/settings/icon-courses.svg);
}

.icon-list-item-image.icon-expected-visitors {
  background-image: url(../images/settings/icon-expected-visitors.svg);
}

.icon-list-item-image.icon-menus {
  background-image: url(../images/settings/icon-menus.svg);
}

.icon-list-item-image.icon-pdf {
  background-image: url(../images/settings-old/pdf.svg);
}

.icon-list-item-image.icon-templates {
  background-image: url(../images/settings/icon-templates.svg);
}

.icon-list-item-image.icon-key {
  background-image: url(../images/key.svg);
}

.icon-list-item-image.icon-copy-issue,
.icon-list-item-image.icon-copy-theme {
  background-image: url(../images/settings-old/copy-old.svg);
}

.icon-list-item-image.icon-silver-key {
  background-image: url(../images/settings-old/silver-key.svg);
}

.icon-list-item-image.icon-tigerkits {
  background-image: url(../images/settings/icon-tigerkits.svg);
}

.icon-list-item-image.icon-delete {
  background-image: url(../images/settings-old/delete.svg);
}

.icon-list-item-image.icon-domain-names {
  background-image: url(../images/settings/icon-domain-names.svg);
}

.icon-list-item-image.icon-publication-groups {
  background-image: url(../images/settings/icon-publication-groups.svg);
}

.icon-list-item-image.icon-publication-notifications {
  background-image: url(../images/distribute/icon-distribute-email.svg);
}

.icon-list-item-image.icon-resources {
  background-image: url(../images/icons/icon-resources.svg);
}

.icon-list-item-image.icon-security-rules {
  background-image: url(../images/settings/icon-security-rules.svg);
}

.icon-list-item-image.icon-styles {
  background-image: url(../images/settings/icon-styles.svg);
}

.icon-list-item-image.icon-tags {
  background-image: url(../images/settings/icon-tags.svg);
}

.icon-list-item-image.icon-template-theme {
  background-image: url(../images/settings/icon-templates.svg);
}

.icon-list-item-image.icon-themes {
  background-image: url(../images/settings/icon-themes.svg);
}

/* DISTRIBUTE PAGE
================== */
/* stylelint-disable-next-line selector-max-class */
.mod-full-width .icon-list-item-image.icon-tiger-mailer {
  top: -3rem;
  width: 35rem;
  height: 35rem;
}

.mod-full-width .icon-list-item-heading {
  font-size: 4rem;
}

.mod-full-width .icon-list-item-text {
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

/* stylelint-disable-next-line no-descending-specificity */
.icon-list-item-image.icon-tiger-mailer {
  background-image: url(../images/distribute/icon-tiger-mailer.svg);
}

.icon-list-item-image.icon-distribute-email {
  background-image: url(../images/distribute/icon-distribute-email.svg);
}

.icon-list-item-image.icon-distribute-text {
  background-image: url(../images/distribute/icon-distribute-text.svg);
}

.icon-list-item-image.icon-distribute-link {
  background-image: url(../images/distribute/icon-distribute-link.svg);
}

.icon-list-item-image.icon-distribute-website {
  background-image: url(../images/distribute/icon-distribute-website.svg);
}

.icon-list-item-image.icon-distribute-facebook {
  background-image: url(../images/distribute/icon-distribute-facebook.svg);
}

/* RECENT PAGE
============== */
.icon-list-item-image.icon-registrations {
  background-image: url(../images/icons/icon-registrations.svg);
}

.icon-list-item-image.icon-publications {
  background-image: url(../images/icons/icon-publications.svg);
}

.icon-list-item-image.icon-issues {
  background-image: url(../images/icons/icon-issues.svg);
}

.icon-list-item-image.icon-published {
  background-image: url(../images/icons/icon-published.svg);
}

/* FINANCE PAGE
=============== */
.icon-list-item-image.icon-print {
  background-image: url(../images/icons/icon-print.svg);
}

.icon-list-item-image.icon-email {
  background-image: url(../images/icons/icon-email.svg);
}

.icon-list-item-image.icon-invoices {
  background-image: url(../images/icons/icon-invoices.svg);
}

.icon-list-item-image.icon-invoice {
  background-image: url(../images/icons/icon-invoice.svg);
}

.icon-list-item-image.icon-exchange {
  background-image: url(../images/icons/icon-exchange.svg);
}

/* DEV TEAM
=========== */
.icon-list-item-image.icon-procurement {
  background-image: url(../images/icons/icon-procurement.svg);
}

.icon-list-item-image.icon-copy-template {
  background-image: url(../images/settings-old/document.svg);
}

.icon-list-item-image.icon-special-files {
  background-image: url(../images/icons/icon-special-files.svg);
}

/* SALES
======== */
.icon-list-item-image.icon-sales-dashboard {
  background-image: url(../images/icons/icon-sales-dashboard.svg);
}

.icon-list-item-image.icon-account-groups {
  background-image: url(../images/icons/icon-account-groups.svg);
}

.icon-list-item-image.icon-quotes {
  background-image: url(../images/icons/icon-quotes.svg);
}

.icon-list-item-image.icon-new-account {
  background-image: url(../images/icons/icon-new-account.svg);
}

.icon-list-item-image.icon-promotions {
  background-image: url(../images/icons/icon-promotions.svg);
}

.icon-list-item-image.icon-messages {
  background-image: url(../images/icons/icon-messages.svg);
}

.icon-list-item-image.icon-contacts {
  background-image: url(../images/icons/icon-contacts.svg);
}

.icon-list-item-image.icon-deals {
  background-image: url(../images/icons/icon-deals.svg);
}

.icon-list-item-image.icon-tasks {
  background-image: url(../images/icons/icon-tasks.svg);
}

.icon-list-item-image.icon-account-summary {
  background-image: url(../images/icons/icon-account-summary.svg);
}

.icon-list-item-image.icon-leadiro {
  background-image: url(../images/icons/icon-leadiro.svg);
}

.icon-list-item-image.icon-projects {
  background-image: url(../images/icons/icon-projects.svg);
}

.icon-list-item-image.icon-timesheet {
  background-image: url(../images/icons/icon-timesheet.svg);
}

.icon-list-item-image.icon-tigerforce-crm {
  background-image: url(../images/icons/icon-tigerforce-crm.svg);
}

.icon-list-item-image.icon-contact-export {
  background-image: url(../images/icons/icon-contact-export.svg);
}

/* TIGERPODS
============ */
.icon-list-item-image.icon-playlist {
  background-image: url(../images/icons/icon-playlist.svg);
}

.icon-list-item-image.icon-devices {
  background-image: url(../images/icons/icon-devices.svg);
}

.icon-list-item-image.icon-modes {
  background-image: url(../images/icons/icon-modes.svg);
}

.icon-list-item-image.icon-download {
  background-image: url(../images/icons/icon-download.svg);
}

.icon-list-item-image {
  position: relative;
  top: -1.8rem;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  background: center / contain no-repeat;
  pointer-events: none;
}

/* stylelint-disable no-descending-specificity */
.icon-list-item-heading {
  display: block;
  position: relative;
  top: -1rem;
  width: 100%;
  font-family: Lato, sans-serif;
  font-size: 2.1rem;
  font-weight: bold;
  color: #5e5e5e;
  pointer-events: none;
}

.icon-list-item-text {
  font-family: Lato, sans-serif;
  color: #8c8c8c;
  pointer-events: none;
}
