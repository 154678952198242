.table-topper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

/* stylelint-disable-next-line selector-max-type */
.table-topper-select-all input {
  width: 9.2rem;
}

.button-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.button-controls-left {
  justify-content: left;
}

/* stylelint-disable-next-line selector-max-type */
.dropdown-controls input {
  margin-right: 1rem;
}

/* stylelint-disable-next-line selector-max-type */
.button-controls input:not(:first-child),
.button-controls button:not(:first-child) {
  margin-left: 1.4rem;
}

.dropdown-controls {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* stylelint-disable-next-line selector-max-type */
.dropdown-controls select {
  margin-right: 1.4rem;
}

.table-footer {
  padding: 0 1rem;
}
