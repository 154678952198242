.add-note-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.add-note-icon {
  display: block;
  height: 100%;
  background: url(../images/notes.svg#grey) center / contain no-repeat;
}
