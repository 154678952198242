@import 'settings.css';

.create-container {
  display: flex;
}

.create-item {
  flex: 1 1 0;
  margin: 1rem;
}

@media (max-width: 1150px) {
  .create-container {
    flex-wrap: wrap;
  }

  .create-item {
    flex: 1 1 calc(50% - 2rem);
  }
}

@media (max-width: 800px) {
  .create-container {
    flex-direction: column;
  }
}

.new-issue-option {
  display: block;
  height: 15rem;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  background: #edeef0 center / 150px no-repeat;
}

.new-issue-option.mod-design {
  background-image: url(../images/create/from-design.svg);
}

.new-issue-option.mod-file {
  background-image: url(../images/create/from-file.svg);
  background-size: 100px;
}
