/* stylelint-disable selector-no-vendor-prefix, property-no-vendor-prefix */
:root {
  --trackColour: #e1e1e1;
  --trackHeight: 1.3rem;
  --trackRadius: 2.5rem;
  --thumbColour: #64b1f5;
  --thumbColourHover: #72ade1;
  --thumbSize: 3.6rem;
  --thumbShadow: 0 0.2rem 0.3rem #c5c5c5;
  --thumbTopPosition: -1.3rem;
}

.form-section + .form-section {
  margin-top: 4rem;
}

.form-heading {
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid #d4d4d4;
  margin: 4.5rem 0 2rem;
}

/* Bit of a hack for the filter controls only */
.form-element-label-block {
  display: block;
  margin-bottom: 0.7rem;
}

.form-element {
  display: block;
  break-inside: avoid;
}

.form-element.mod-flex {
  display: flex;
}

.form-element + .form-element,
.form-element + .primary-button {
  margin-top: 1.8rem;
}

.form-element-inline {
  display: inline-block;
}

.form-element-inline + .form-element-inline {
  margin-left: 1rem;
}

.form-element-fieldset {
  padding: 0;
  border: none;
  margin: 0;
}

.form-element-info-asterisk {
  color: #f00;
  cursor: help;
}

.form-element-label {
  display: inline-block;
  margin-bottom: 0.7rem;
}

.form-element-label.is-full {
  display: block;
}

.form-element-label.is-option {
  display: flex;
  align-items: center;
  position: relative;
  margin: 1.2rem 0;
}

.form-element-label-text,
.form-element-legend {
  font-weight: bold;
}

.form-element-label-link {
  float: right;
  font-size: 1.3rem;
  color: #4095ef;
}

.form-element-label-required {
  color: #cb0000;
}

.form-element-label-description {
  display: block;
  margin-top: 0.4rem;
  font-size: 1.5rem;
}

.form-element-input,
.form-element-select,
.form-element-textarea {
  display: block;
  max-width: 100%;

  /* Needs a height to make the select element and the input element the same in IE11 */
  height: 3.6rem;
  padding: 0.8rem;
  border: var(--borderDark);
  border-radius: var(--borderRadius);
  margin: 0;
  font: inherit;
}

.form-element-input.mod-textarea,
.form-element-textarea {
  width: 47rem;
  min-width: 20rem;
  height: 16rem;
  min-height: 10rem;
  overflow: auto;
}

.form-element-checkbox {
  display: block;
  margin: 0;
}

.form-element-checkbox.mod-inline {
  margin: 0.5rem 1rem 0 0;
}

.form-element-checkboxlist {
  width: fit-content;
  min-height: 3.6rem;
  max-height: 200px;
  overflow-y: scroll;
  padding: 6px;
  border: var(--borderDark);
  border-radius: var(--borderRadius);
}

.form-element-checkboxlist.is-extra-large {
  min-width: 40rem;
}

.form-element-checkboxlist label {
  padding: 0.5rem;
}

.form-element-select {
  padding: 0 3.2rem 0 0.8rem;
  text-indent: 0.01px;
  background: #fff url(../images/down-arrow.svg) right 0.9rem center / auto 45% no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-element-select.is-multiple {
  min-height: 20rem;
  padding: 0;
  background: #fff;
}

.form-element-input::-webkit-input-placeholder {
  font-style: italic;
  color: #828282;
}

.form-element-input:-ms-input-placeholder {
  color: #828282;
}

.form-element-input::placeholder {
  font-style: italic;
  color: #828282;
}

.form-element-select::-ms-expand {
  display: none;
}

.form-element-option-text {
  margin-left: 1rem;
}

/* Temp hack. Remove me once div's are switched for inputs */
/* stylelint-disable-next-line selector-no-qualifying-type, selector-max-type */
div.form-element-input,
.form-element-input:disabled,
.form-element-input:read-only,
.form-element-select:disabled,
.form-element-textarea:disabled,
.form-element-checkbox:disabled,
.form-element-textarea:read-only {
  background-color: #ececec;
}

.form-element-input.is-inline,
.form-element-select.is-inline,
.form-element-textarea.is-inline {
  display: inline-block;
}

.form-element-input.is-extra-small,
.form-element-textarea.is-extra-small {
  width: 5rem;
}

.form-element-select.is-extra-small {
  width: 6rem;
}

.form-element-input.is-small,
.form-element-select.is-small,
.form-element-textarea.is-small {
  width: 10rem;
}

.form-element-input.is-medium-small,
.form-element-select.is-medium-small,
.form-element-textarea.is-medium-small {
  width: 15rem;
}

.form-element-input.is-medium,
.form-element-select.is-medium,
.form-element-textarea.is-medium {
  width: 20rem;
}

.form-element-input.is-large,
.form-element-select.is-large,
.form-element-textarea.is-large {
  width: 30rem;
}

.form-element-input.is-extra-large,
.form-element-select.is-extra-large,
.form-element-textarea.is-extra-large {
  width: 40rem;
}

.form-element-input.is-extra-extra-large,
.form-element-select.is-extra-extra-large,
.form-element-textarea.is-extra-extra-large {
  width: 60rem;
}

.form-element-input.is-full,
.form-element-select.is-full,
.form-element-textarea.is-full {
  width: 100%;
}

.form-element-input.is-height-auto {
  height: auto;
}

.form-element-label-error {
  display: block;
  margin-top: 0.7rem;
  color: var(--errorColour);
}

.form-element-range {
  width: 100%;
  margin: 1rem 0;
  -webkit-appearance: none;
}

.form-element-range::-webkit-slider-runnable-track {
  width: 100%;
  height: var(--trackHeight);
  border-radius: var(--trackRadius);
  background: var(--trackColour);
  cursor: pointer;
}

.form-element-range::-webkit-slider-thumb {
  width: var(--thumbSize);
  height: var(--thumbSize);
  border-radius: 50%;
  margin-top: var(--thumbTopPosition);
  box-shadow: var(--thumbShadow);
  background: var(--thumbColour);
  cursor: pointer;
  -webkit-appearance: none;
}

.form-element-range::-moz-range-track {
  width: 100%;
  height: var(--trackHeight);
  border-radius: var(--trackRadius);
  background: var(--trackColour);
  cursor: pointer;
}

.form-element-range::-moz-range-thumb {
  width: var(--thumbSize);
  height: var(--thumbSize);
  border: none;
  border-radius: 50%;
  margin-top: var(--thumbTopPosition);
  box-shadow: var(--thumbShadow);
  background: var(--thumbColour);
  cursor: pointer;
}

@media all and (-ms-high-contrast: none) {
  /* stylelint-disable-next-line selector-max-universal */
  *::-ms-backdrop,
  .form-element-range {
    display: none;
  }
}

.form-element-range::-ms-track {
  width: 99%;
  height: var(--trackHeight);
  border-radius: 0;
  margin: 1.3rem 0;
  background: var(--trackColour);
  cursor: pointer;
}

.form-element-range::-ms-fill-lower {
  border: none;
  background: var(--trackColour);
}

.form-element-range::-ms-fill-upper {
  border: none;
  background: var(--trackColour);
}

.form-element-range::-ms-thumb {
  width: var(--thumbSize);
  height: var(--thumbSize);
  border: none;
  margin-top: -0.5rem;
  box-shadow: var(--thumbShadow);
  background: var(--thumbColour);
  cursor: pointer;
}

.form-element-range:--focus-hover {
  outline: none;
}

.form-element-range::-webkit-slider-thumb:--focus-hover {
  background: var(--thumbColourHover);
}

.form-element-range::-moz-range-thumb:--focus-hover {
  background: var(--thumbColourHover);
}
