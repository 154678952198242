.deal-contact-popup {
  display: inline-block;
  width: 100%;
}

.deal-contact-info {
  float: left;
}

.deal-contact-associated-deals {
  float: right;
  margin-left: 5rem;
}
