.auth-footer {
  display: flex;
  flex-wrap: wrap;
  max-width: 43rem;
  padding: 0 2rem;
  margin: 2rem auto 7rem;
}

.auth-footer-item {
  width: 50%;
  margin-bottom: 0.7rem;
  text-align: center;
}

.auth-footer-item-link {
  color: #505050;
}
