/* THIS FILE HAS HAD LINTING DISABLED BECAUSE IT'S CAUSING
THE LINTING TO FAIL. NEXT PERSON TO WORK ON IT PLEASE FIX */
/* stylelint-disable */

@import 'settings.css';

.flag {
  position: absolute;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  margin-top: -1rem;
  margin-left: -1rem;
  background: url(../images/flag.svg#grey) center / contain no-repeat;
}

.flag-button {
  width: 3rem;
  height: 3rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.flag-mod-large {
  width: 5rem;
  height: 5rem;
}

.flag-mod-add {
  opacity: 0.4;
  background: url(../images/add-flag.svg) center / contain no-repeat;
}

.flag-mod-blue {
  background: url(../images/flag.svg#blue) center / contain no-repeat;
}

.flag-mod-green {
  background: url(../images/flag.svg#green) center / contain no-repeat;
}

.flag-mod-orange {
  background: url(../images/flag.svg#orange) center / contain no-repeat;
}

.flag-mod-yellow {
  background: url(../images/flag.svg#yellow) center / contain no-repeat;
}

.flag-mod-red {
  background: url(../images/flag.svg#red) center / contain no-repeat;
}

#flagPopupDescription {
  margin-bottom: 5rem;
  text-align: center;
}

#flagPopupIcon {
  height: 3rem;
  margin-top: 2rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

#flagPopupEditButton {
  text-align: center;
}

#saveFlag {
  margin-top: 2rem;
}

.flagPopupProperty {
  margin-bottom: 1rem;
}

.flagPopupPropertyName {
  font-weight: bold;
}

.flagPopupPropertyValue {
  margin-top: -0.6rem;
  color: #8c8c8c;
}

#flagPopupLeft {
  display: inline-block;
  margin-top: 1rem;
  margin-right: 10rem;
}

#flagPopupRight {
  display: inline-block;
}
