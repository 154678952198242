@import 'settings.css';

.auth-container {
  max-width: 43rem;
  padding: 0 2rem;
  margin: 2rem auto 3rem;
}

.auth-logo {
  height: 8rem;
  background: url(../images/page-tiger-logo-black.svg) no-repeat;
  background-position: center;
  margin: 4rem auto;
  background-size: contain;
}

.auth-form {
  display: block;
  padding: 2rem;
  border: var(--borderDark);
  border-radius: var(--borderRadius);
  background-color: #fff;
}

.auth-heading {
  margin: 1rem 0 3rem;
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: -0.1rem;
  text-align: center;
  color: #000;
}

.auth-submit {
  width: 100%;
  font-size: inherit;
}

.auth-password-reset {
  margin-bottom: 3rem;
  text-align: center;
}
