:root {
  --gapSize: 5rem;
}

.pt-home-container + .pt-home-container {
  margin-top: var(--gapSize);
}

.pt-home-report {
  max-height: 300px;
  overflow: auto;
  border: 0.1rem solid #e8e8e8;
}

.pt-home-report-link {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.pt-home-links {
  margin-top: var(--gapSize);
}

.pt-home-links-link {
  margin-top: 1.3rem;
}

.pt-home-link-section-heading {
  margin: 0 0 1.5rem;

  /* stylelint-disable-next-line */
  font-size: 1.8rem !important;
  color: #333;
}
