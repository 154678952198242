@import 'settings.css';

.issue-preview {
  display: flex;
  padding: 1.2rem;
  border: 0.1rem solid #d0d0d0;
  border-radius: var(--borderRadius);
  margin-bottom: 4rem;
}

.issue-preview-image {
  display: block;
  min-width: 18rem;
  max-width: 28rem;
  min-height: 12rem;
  max-height: 16rem;
  margin-right: 2rem;
  background: center / contain no-repeat;
}

.issue-preview-meta-title {
  margin-bottom: 2rem;
  font-size: 2.7rem;
  font-weight: bold;
  line-height: 1.1;
}

.issue-preview-meta-details {
  display: flex;
  align-items: center;
  margin: 1.8rem 0;
  font-size: 1.7rem;
  color: #737373;
}

.issue-preview-meta-details-bold {
  margin-right: 0.5rem;
  font-size: 2.6rem;
  font-weight: bold;
  letter-spacing: -0.1rem;
  color: #404040;
}

.issue-preview-image.presentation {
  background-image: url(../images/create/icon-presentation.svg);
}

.issue-preview-image.book {
  background-image: url(../images/create/icon-ebook.svg);
}

.issue-preview-image.blank {
  background-image: url(../images/create/icon-blank.svg);
}
