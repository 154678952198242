@import './settings.css';

.progress {
  height: 3rem;
  overflow: hidden;
  border-radius: var(--borderRadius);
  font-size: 0.75rem;
  background-color: #e9ecef;
}

.progress.mod-small {
  height: 1.2rem;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #9dc272;
  transition-property: transform;
  transition-timing-function: ease-in;
  transform-origin: left center;
}

.progress-text {
  margin-top: 1.2rem;
  font-size: 1.7rem;
  text-align: center;
}

.progress-error-message {
  margin: 2rem 0 0.5rem;
  text-align: center;
  color: var(--tigerRed);
}
