/* stylelint-disable */
p {
  margin: 5px 0;
  line-height: 18px;
}

b,
strong {
  font-weight: bold;
}

h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #458ed6;
}

h3 a {
  font-size: 14px;
  font-weight: bold;
  color: #458ed6;
}

h4:not(.dynamic-form-message-title):not(.dynamic-form-title) {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

h5 {
  font-size: 11px;
  font-weight: bold;
  color: #333;
}

h6 {
  font-size: 10px;
  font-weight: normal;
  color: #333;
}

a,
.a {
  text-decoration: none;
  color: #2e78c1;
}

a:--focus-hover {
  color: #69b3fd;
}

a.fadedLink {
  color: #a2c6ea;
}

a.fadedLink:hover {
  color: #458ed6;
}

a.disabledLink {
  color: #a2c6ea;
}

a.disabledLink:hover {
  text-decoration: none;
  color: #a2c6ea;
  cursor: default;
}

h2.light {
  border-bottom: solid 2px #eee;
  color: #999;
}

h2.bright {
  border-bottom: solid 2px #f5811c;
  margin: 0 0 10px;
  color: #333;
}

hr {
  padding: 0;
  border: none;
  border-top: solid 1px #ccc;
  margin: 10px 0;
}

.bigText,
a.bigText,
.bigText a {
  margin: 20px 0;
  font-size: 32px;
}

.mediumText,
a.mediumText,
.mediumText a {
  margin: 10px 0;
  font-size: 16px;
}

.tinyInput {
  width: 50px;
}

select.tinyInput {
  width: 40px;
}

.shortInput {
  width: 90px;
}

select.shortInput {
  width: 80px;
}

.mediumInput {
  width: 200px;
}

select.mediumInput {
  width: 190px;
}

.longInput {
  width: 300px;
}

select.longInput {
  width: 290px;
}

.longMultilineInput {
  width: 300px;
  height: 200px;
}

.veryLongInput {
  width: 400px;
}

select.veryLongInput {
  width: 390px;
}

input.radioInput {
  margin-right: 5px;
}

span.radioInput input {
  margin-right: 5px;
}

.longInput,
select.longInput,
.longMultilineInput,
.veryLongInput,
select.veryLongInput,
.tinyInput,
select.tinyInput,
.shortInput,
select.shortInput,
.mediumInput,
select.mediumInput {
  max-width: 100%;
}

.code,
.CodeEditor {
  font-family: 'Courier New', Courier, monospace;
  tab-size: 4;
}

.InputRO {
  display: inline-block;
  height: 20px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.multilineInputRO {
  display: inline-block;
  min-height: 20px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.tinyInputRO {
  display: inline-block;
  width: 50px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;

  /* height: 20px; */
  line-height: 20px;
  background-color: #f6f6f6;
}

.shortInputRO {
  display: inline-block;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;

  /* width: 100px; */
  /* height: 20px; */
  line-height: 20px;
  background-color: #f6f6f6;
}

.mediumInputRO {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;

  /* height: 20px; */
  line-height: 20px;
  background-color: #f6f6f6;
}

.longInputRO {
  display: inline-block;
  width: 300px;
  min-height: 2.6rem;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;

  /* height: 20px; */
  line-height: 20px;
  background-color: #f6f6f6;
  vertical-align: top;
}

.InputRO99Percent {
  display: inline-block;
  width: 99%;
  height: 20px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.longMultilineInputRO {
  display: inline-block;
  width: 300px;
  min-height: 20px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.veryLongInputRO {
  display: inline-block;
  width: 400px;
  height: 20px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.veryLongMultilineInputRO {
  display: inline-block;
  width: 400px;
  min-height: 20px;
  overflow: hidden;
  padding: 3px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.longInput,
select.longInput,
.longMultilineInput,
.veryLongInput,
select.veryLongInput,
.tinyInput,
select.tinyInput,
.shortInput,
select.shortInput,
.mediumInput,
select.mediumInput .InputRO,
.multilineInputRO,
.tinyInputRO,
.shortInputRO,
.mediumInputRO,
.longInputRO,
.InputRO99Percent,
.longMultilineInputRO,
.veryLongInputRO,
.veryLongMultilineInputRO {
  max-width: 100%;
}

.tinyImageDisplay {
  width: 50px;
  height: 150px;
  overflow: auto;
  border: solid 1px #ccc;
  background-color: #666;
}

.shortImageDisplay {
  width: 100px;
  height: 150px;
  overflow: auto;
  border: solid 1px #ccc;
  background-color: #666;
}

.mediumImageDisplay {
  width: 200px;
  height: 150px;
  overflow: auto;
  border: solid 1px #ccc;
  background-color: #666;
}

.longImageDisplay {
  width: 200px;
  height: 200px;
  overflow: auto;
  border: solid 1px #ccc;
  background-color: #666;
}

.lockedInputRO {
  background-color: #fff8f8;
}

.tall {
  height: 300px;
}

.wide {
  width: 700px;
}

.scrolling {
  overflow: auto;
}

.width50 {
  width: 50px;
}

.width60 {
  width: 60px;
}

.width70 {
  width: 70px;
}

.width80 {
  width: 80px;
}

.width90 {
  width: 90px;
}

.width100 {
  width: 100px;
}

.width150 {
  width: 150px;
}

.width200 {
  width: 200px;
}

.width300 {
  width: 300px;
}

.width400 {
  width: 400px;
}

.width500 {
  width: 500px;
}

.width50Percent {
  width: 50%;
}

.width75Percent {
  width: 75%;
}

.width80Percent {
  width: 80%;
}

.width90Percent {
  width: 90%;
}

.width99Percent {
  width: 99%;
}

.width100Percent {
  width: 100%;
}

.height50 {
  height: 50px;
}

.height100 {
  height: 100px;
}

.height150 {
  height: 150px;
}

.height200 {
  height: 200px;
}

.height300 {
  height: 300px;
}

.height400 {
  height: 400px;
}

.height500 {
  height: 500px;
}

.height600 {
  height: 600px;
}

.height800 {
  height: 800px;
}

.minheight100 {
  min-height: 100px;
}

.minheight200 {
  min-height: 200px;
}

.minheight300 {
  min-height: 300px;
}

.minheight400 {
  min-height: 400px;
}

.minheight500 {
  min-height: 500px;
}

.minheight800 {
  min-height: 800px;
}

.maxheight100 {
  max-height: 100px;
}

.maxheight200 {
  max-height: 200px;
}

.maxheight300 {
  max-height: 300px;
}

.maxheight400 {
  max-height: 400px;
}

.maxheight500 {
  max-height: 500px;
}

.maxheight800 {
  max-height: 800px;
}

.lineheight100 {
  line-height: 100px;
}

.lineheight150 {
  line-height: 150px;
}

.lineheight200 {
  line-height: 200px;
}

.lineheight300 {
  line-height: 300px;
}

.lineheight400 {
  line-height: 400px;
}

.lineheight500 {
  line-height: 500px;
}

.lineheight800 {
  line-height: 800px;
}

.margin5 {
  margin: 5px;
}

.margin10 {
  margin: 10px;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginTop5 {
  margin-top: 5px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.ir {
  display: block;
  text-indent: -5000px;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.width-auto {
  width: auto;
}

/* .highLight {
  color: #458ed6;
} */
.strikeThrough {
  text-decoration: line-through;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.border {
  border: solid 1px #ccc;
}

.noborder {
  border: none;
}

.paraUL {
  padding-left: 20px;
}

.inlineNbsp30 {
  display: inline-block;
  width: 30px;
}

.DisplayInlineBlock {
  display: inline-block;
}

.DisplayBlock {
  display: block;
}

.NoUnderline {
  text-decoration: none;
}

.NoUnderline:hover {
  text-decoration: none;
}

.htmlCode300 {
  padding: 10px;
  border: solid 1px #ccc;
}

.htmlCode300 textarea {
  width: 100%;
  height: 300px;
  border: none;
  font-size: 12px;
}

.htmlCode60 {
  padding: 10px;
  border: solid 1px #ccc;
}

.htmlCode60 textarea {
  width: 100%;
  height: 60px;
  overflow: auto;
  border: none;
  font-size: 14px;
}

.settingsIntro {
  height: 110px;
}

.settingsSettingList {
  height: 200px;
}

.ptiFormContainer {
  width: 100%;
}

.ptiFormContainer .ptiLeftCol {
  width: 1%;
  padding-right: 10px;
}

.ptiFormContainer .ptiRightCol {
  padding-left: 10px;
}

.ptiSection {
  border: none;
  margin-bottom: 20px;
}

.ptiSectionNew {
  background-color: #f3f7ef;
}

.ptiSectionAdmin {
  background-color: var(--pageTigerOnlyBG);
}

.ptiSectionDelete {
  background-color: #fff8f8;
}

.ptiSectionInner .homeLinksText {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  color: #458ed6;
}

.ptiSectionNew .ptiSectionInner h2 {
  margin-top: 10px;
  margin-left: 10px;
}

.ptiSectionAdmin .ptiSectionInner h2 {
  margin-top: 10px;
  margin-left: 10px;
  color: #635614;
}

.ptiSectionDelete .ptiSectionInner h2 {
  margin-top: 10px;
  margin-left: 10px;
}

.ptiForm {
  width: 100%;
  border-spacing: 0;
}

.ptiForm .ptiFormHeader td,
.ptiForm .ptiFormHeader th {
  text-align: center;
  color: #fff;
  background-color: #666;
}

.ptiForm td {
  padding: 10px;
}

.TextLabel {
  width: 170px;
  margin-right: 2rem;
  text-align: right;
}

.TextLabelExtraShort {
  width: 80px;
}

.TextLabelShort {
  width: 170px;
}

.TextLabelMedium {
  width: 200px;
}

.TextLabelLong {
  width: 250px;
}

.HelpMessage {
  max-width: 50rem;
}

.ptiForm td p,
.ptiForm td ul li {
  color: #666;
}

.ptiForm .warning li {
  color: #cb0000;
}

input[type='checkbox'] {
  margin: 0;
}

.ptiForm tr.PTIAdminRow td.TextLabelNoIcon {
  background-color: var(--pageTigerOnlyBG);
}

.ptiForm tr.PTIDevRow td.TextLabelNoIcon {
  background-color: #fff8f8;
}

.ptiSectionNew .ptiSectionInner h2 {
  color: #7ca553;
}

.ptiSectionAdmin .ptiForm .TextLabel {
  background-color: var(--pageTigerOnlyBG);
}

.ptiSectionDelete .ptiSectionInner h2 {
  color: #ff7070;
  border-bottom-color: #ffe7e7;
}

.ptiSectionDelete .ptiForm .TextLabel {
  background-color: #fff8f8;
}

.manageIssueList {
  margin: 2rem 0;
}

.PTIDev {
  background-color: #fff8f8;
}

.PlusFeature {
  position: relative;
  border: solid 1px #ccc;
  border-radius: 0.4rem;
  cursor: pointer;
}

.PlusFeature .PlusBlank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/images/PlusFeature.png) repeat;
}

.PlusFeaturePad {
  padding: 10px;
}

.PlusFeature.PlusFeatureNoBorder {
  border: none;
}

.EnterpriseFeature {
  position: relative;
  border: solid 1px #ccc;
  cursor: pointer;
}

.EnterpriseFeature .EnterpriseBlank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/images/EnterpriseFeature.png) repeat;
}

.EnterpriseFeaturePad {
  padding: 10px;
}

.EnterpriseFeature.EnterpriseFeatureNoBorder {
  border: none;
}

.EnterprisePlusFeature {
  position: relative;
  border: solid 1px #ccc;
  cursor: pointer;
}

.EnterprisePlusFeature .EnterprisePlusBlank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/images/EnterprisePlusFeature.png) repeat;
}

.EnterprisePlusFeaturePad {
  padding: 10px;
}

.EnterprisePlusFeature.EnterprisePlusFeatureNoBorder {
  border: none;
}

.ptiFormLayoutOnly {
  border-spacing: 0;
}

.ptiFormLayoutOnly td {
  padding: 4px;
}

.ptiFormLayoutOnly td.ptiFormLayoutOnlyOnLeftPadding {
  padding-left: 0;
}

.ptiFormLayoutOnly td.ptiFormLayoutOnlyOnRightPadding {
  padding-right: 0;
}

.ptiFormLayoutOnly td.ptiFormLayoutOnlyOnPadding {
  padding: 0;
}

.ptiFormLayoutOnly td.TextLabel {
  padding-right: 25px;
  line-height: 24px;
  text-align: right;
}

.ptiFormLayoutOnly td.TextLabelNoIcon {
  padding-right: 5px;
  line-height: 24px;
  text-align: right;
}

.ptiFormLayoutHeading {
  border-bottom: solid 2px #ddd;
  margin: 0 0 11px;
  border-spacing: 0;
}

.ptiFormLayoutHeading td {
  padding: 5px;
  line-height: 26px;
}

.ptiFormLayoutHeading td.Heading {
  padding-left: 0;
  font-size: 18px;
  font-weight: normal;
  color: #666;
}

.ptiFormLayoutHeading td.ptiFormLayoutOnlyOnLeftPadding {
  padding-left: 0;
}

.ptiFormLayoutHeading td.ptiFormLayoutOnlyOnRightPadding {
  padding-right: 0;
}

.ptiFormLayoutHeading .TextLabel {
  padding-right: 30px;
  line-height: 24px;
  text-align: right;
}

.ptiFormLayoutHeading .TextLabelNoIcon {
  padding-right: 0;
  line-height: 24px;
  text-align: right;
}

.InstructionMessage {
  padding: 10px 0;
}

.InstructionMessage p {
  color: #666;
}

.InstructionMessage ul {
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.InstructionMessage ul li {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.InstructionMessage ul li a {
  font-size: 14px;
  line-height: 20px;
}

.InstructionMessageLarge {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 15rem;
  min-height: 17.7rem;
  padding: 10px;
  border-radius: 0 0 0.4rem 0.4rem;
  box-shadow: 0 3px 5px #c7c7c7;
  background-color: #fff;
  background-color: #fff;
}

.InstructionMessageLarge p {
  font-size: 15px;
  line-height: 24px;
  color: #5a5a5a;
}

.InstructionMessageLarge p a {
  font-size: 16px;
  line-height: 24px;
}

.InstructionMessageLarge ul {
  margin: 10px 0;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.InstructionMessageLarge ul li {
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.InstructionMessageLarge ul li a {
  font-size: 16px;
  line-height: 24px;
}

.terms-sub-heading {
  margin: 4rem 0 1rem;
  font-size: 18px;
  font-weight: bold;
  color: #458ed6;
}

.Message p {
  line-height: 1.6;
}

.Message ul {
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
}

.Message ul li {
  font-size: 14px;
  line-height: 20px;
  color: #666;
}

.Message ul li a {
  font-size: 14px;
  line-height: 20px;
}

.InfoMessage {
  padding: 10px;
  margin: 5px 0;
}

.InfoMessage.mod-green {
  border: solid 1px #d6e9c6;
  color: #3c763d;
  background-color: #dff0d8;
}

.InfoMessage p {
  font-size: 16px;
  line-height: 1.4;
}

.InfoMessage ul {
  margin: 10px 0;
}

.InfoMessage ul li {
  color: #3c763d;
}

.WarningBanner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.WarningMessage {
  padding: 10px;
  border: solid 1px #ebccd1;
  margin: 20px 0;
  line-height: 1.5;
  color: #a94442;
  background-color: #f2dede;
}

.ControlsRow {
  display: block;
  padding: 5px 5px 5px 0;
  margin-top: 2px;
  margin-bottom: 5px;
}

.ControlsRow span {
  padding: 0;
  margin: 0;
  line-height: 24px;
}

.ControlsRowBig {
  /* TODO -  Check this doesn't break anyting */
  display: inline-flex;
}

.ControlsRowBig input[type='submit']:not(:first-child) {
  margin-left: 2rem;
}

.ControlsRowRadio {
  display: block;
  padding: 5px 5px 5px 0;
  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 24px;
}

.ControlsRowRadio span {
  padding: 0;
  margin: 0;
  line-height: 24px;
}

.ControlsRowSmall {
  display: block;
  padding: 5px 5px 5px 0;
  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 16px;
}

.ControlsRowSmall span {
  padding: 0;
  margin: 0;
  line-height: 16px;
}

.PagingRow {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 24px;
  text-align: left;
}

.PagingRow span {
  padding: 0 10px;
  margin: 0 10px 0 0;
  line-height: 24px;
}

.PagingRow input {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 10px;
  margin-left: 0;
}

.lbl {
  height: 20px;
  overflow: hidden;
  padding-right: 5px;
  padding-left: 5px;
  line-height: 20px;
  text-align: right;
}

.data {
  height: 20px;
  overflow: hidden;
  padding-right: 5px;
  padding-left: 5px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.dataInfo {
  overflow: hidden;
  padding-right: 5px;
  padding-left: 5px;
  border: solid 1px #ccc;
  line-height: 20px;
  background-color: #f6f6f6;
}

.divButtons {
  padding-right: 5px;
  padding-left: 5px;
}

#divSubmitting {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: #fff;
}

#divSubmittingMessage {
  position: fixed;
  top: 300px;
  z-index: 3;
  width: 100%;
  height: 50px;
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;
}

#divSubmittingAnimationNew {
  z-index: 4;
  width: 50px;
  height: 50px;
  border-color: none;
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;
}

.colorpickerpluginswatch {
  padding: 3px 10px;
  border: solid 1px #999;
  margin-left: 5px;
  line-height: 22px;
}

.IssueThumb {
  position: relative;
  width: 170px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.IssueThumb .IssueThumbCover {
  position: relative;
  width: 100%;
  height: 170px;
  border: none;
  margin: 0;
  background-color: #999;
  background-position: center;
  background-repeat: no-repeat;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28px;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon {
  display: block;
  width: 28px;
  height: 28px;
  float: right;
  border-right: solid 1px #fff;
  text-decoration: none;
  background-position: center center;
  background-repeat: no-repeat;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon:hover {
  text-decoration: none;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon:first-child {
  border-right: none;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon.IssueThumbIconsTemplate {
  background-color: #f5811c;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon.IssueThumbIconsTigerKit {
  background-color: #f5811c;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon.IssueThumbIconsFromTigerKit {
  background-color: #f5811c;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon.IssueThumbIconsTigerGenius {
  background-color: #f5811c;
}

.IssueThumb .IssueThumbCover .IssueThumbCoverTigerGenius {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  border-right: none;
  background-color: #f5811c;
  filter: alpha(opacity=30);
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon.IssueThumbIconsPublished {
  width: 80px;
  font-size: 13px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  background-color: #f5811c;
  background-image: none;
}

.IssueThumb .IssueThumbCover .IssueThumbIcons .IssueThumbIcon.IssueThumbIconsScheduled {
  width: 80px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  background-color: #693;
  background-image: none;
}

.IssueThumb .IssueThumbCover .IssueThumbIcon.IssueThumbIconsUpdated {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  background-color: #666;
  background-position: center center;
  background-repeat: no-repeat;
}

.IssueThumb .IssueThumbCover .IssueThumbIcon.IssueThumbIconsUpdated:hover {
  background-color: #f5811c;
}

.IssueThumb .IssueThumbCoverDesc {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin: 0;
  background-color: #f6f6f6;
}

.IssueThumb .IssueThumbCoverDesc h2 {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 20px;
  left: 5px;
  padding: 0;
  border-bottom: none;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  color: #333;
  background: none;
  text-transform: none;
}

.IssueThumb .IssueThumbCoverDesc p {
  position: absolute;
  right: 5px;
  bottom: 5px;
  left: 5px;
  height: 16px;
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  background: none;
  text-transform: none;
}

.IssueThumb .IssueThumbLinks {
  position: relative;
  width: 100%;
  height: 24px;
  margin: 0 auto;
  font-size: 13px;
  line-height: 24px;
}

.IssueThumb .IssueThumbLinks .IssueThumbLinksDesign {
  position: absolute;
  top: 0;
  left: 10px;
}

.IssueThumb .IssueThumbLinks .IssueThumbLinksManage {
  text-align: center;
}

.IssueThumb .IssueThumbLinks .IssueThumbLinksView {
  position: absolute;
  top: 0;
  right: 10px;
}

/* Home Issues */
.IssueThumb.HomeIssueThumb {
  width: 170px;
  float: left;
  margin: 0 7px 10px 0;
}

.IssueThumb.HomeIssueThumb .IssueThumbCover {
  height: 170px;
}

/* New Issue Wizard - Select Issue */
#divIssueThumbnail .IssueThumb {
  width: 170px;
}

#divIssueThumbnail .IssueThumb .IssueThumbCover {
  height: 170px;
}

/* New Manage Publication */
#divManageMagIssueThumbs .IssueThumb {
  width: 290px;
  float: left;
  margin: 0 10px 10px 0;
}

#divManageMagIssueThumbs .IssueThumb .IssueThumbCover {
  height: 300px;
}

#divManageMagIssueThumbs .IssueThumbCoverDesc {
  height: 30px;
  line-height: 30px;
}

.publication {
  position: relative;
  height: 70px;
  border: solid 1px #ccc;
}

.publication .lblTitle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 110px;
}

.publication .lblTitleData {
  position: absolute;
  top: 10px;
  left: 140px;
  width: 730px;
}

.publication .lblLink {
  position: absolute;
  top: 40px;
  left: 10px;
  width: 110px;
}

.publication .lblLinkData {
  position: absolute;
  top: 40px;
  left: 140px;
  width: 730px;
}

.publication .divButtons {
  position: absolute;
  top: 70px;
  left: 10px;
  width: 886px;
}

.issue {
  position: relative;
  padding-bottom: 15px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.issue .issueInner {
  position: relative;
  margin: 0;
}

.issueInner .IssueThumb.issueCover {
  position: relative;
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
}

.issueInner .IssueThumb.issueCover .IssueThumbCover {
  height: 250px;
}

/* New Manage Issue */
.manageIssueThumb .IssueThumb.issueCover {
  position: relative;
  width: 250px;
  height: 250px;
}

.publicationIssueThumb .IssueThumb.issueCover {
  width: 250px;
}

.publicationIssueThumb .IssueThumb.issueCover .IssueThumbCover {
  height: 250px;
}

.publicationIssueThumb .IssueThumb.issueCover .IssueThumbCoverDesc {
  height: 30px;
  overflow: hidden;
  line-height: 30px;
}

.publicationIssue .divButtons {
  margin-top: 10px;
  margin-bottom: 10px;
}

.publicationIssue .divButtons input {
  margin-left: 1rem;
}

.publicationIssue {
  border-bottom: solid 1px #ccc;
  margin: 10px 0;
}

.issueNarrow {
  position: relative;
  width: 490px;
  height: 190px;
  margin: 0 0 10px;
  background-color: #fff;
}

.issueNarrow .IssueThumb {
  position: absolute;
  top: 10px;
  left: 10px;
}

.issueNarrow .IssueThumb .IssueThumbCover {
  height: 170px;
}

.issueNarrow .lblPublication {
  position: absolute;
  top: 10px;
  left: 190px;
  width: 90px;
}

.issueNarrow .lblPublicationData {
  position: absolute;
  top: 10px;
  left: 300px;
  width: 175px;
}

.issueNarrow .lblIssue {
  position: absolute;
  top: 40px;
  left: 190px;
  width: 90px;
}

.issueNarrow .lblIssueData {
  position: absolute;
  top: 40px;
  left: 300px;
  width: 175px;
}

.issueNarrow .lblPagination {
  position: absolute;
  top: 70px;
  left: 190px;
  width: 90px;
}

.issueNarrow .lblPaginationData {
  position: absolute;
  top: 70px;
  left: 300px;
  width: 175px;
}

.issueNarrow .lblLastPublished {
  position: absolute;
  top: 130px;
  left: 190px;
  width: 90px;
}

.issueNarrow .lblLastPublishedData {
  position: absolute;
  top: 130px;
  left: 300px;
  width: 175px;
}

.issueNarrow .lblOneOffCreditExpiry {
  position: absolute;
  top: 160px;
  left: 190px;
  width: 90px;
}

.issueNarrow .lblOneOffCreditExpiryData {
  position: absolute;
  top: 160px;
  left: 300px;
  width: 175px;
}

/* Or */
.issueNarrow .lblAutoUnpublish {
  position: absolute;
  top: 160px;
  left: 190px;
  width: 90px;
}

.issueNarrow .lblAutoUnpublishData {
  position: absolute;
  top: 160px;
  left: 300px;
  width: 175px;
}

.pricingOtherProducts {
  padding-bottom: 20px;
  margin: 20px 20px 0;
}

.pricingOtherProductsHeader {
  width: 100%;
  height: 75px;
  background-position: left top;
  background-repeat: no-repeat;
}

#divContainer {
  margin: 10px 0;
}

#divContainerInner {
  min-height: 500px;
  margin: 0 30px;
}

#divContainerInner #pnlBrandingImage {
  margin-bottom: 10px;
}

.divAccountsSubMenu {
  padding: 10px 0;
  border: solid 1px #ccc;
  margin-bottom: 10px;
  background-color: #f6f6f6;
}

.divAccountsSubMenu a {
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
}

.divAccountsMaxRecords {
  padding: 5px;
  border-bottom: solid 1px #c3c3c3;
  background-color: #ddd;
}

.divAccountsMaxRecords a {
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
}

td {
  vertical-align: top;
}

.mod-td-vertical-align-center td {
  vertical-align: middle;
}

#divOtherProducts td {
  vertical-align: middle;
}

.clear {
  clear: both;
}

/*
    .menu {position: relative; width: 960px; margin: 10px auto 0 auto; height: 42px; background: url(../images/images/menu.png) top no-repeat;}
    .menu .logo {width: 70px;background:transparent;display:block;float:left;margin:5px 19px 0 16px;height:38px;position:relative;list-style:none;border:none;}
    .menu .item {width: 111px;height: 25px;text-align:center;float:left;margin:2px 0px 0 0px;padding-top:13px;position:relative;list-style:none;border-left: solid 1px #666;border-right: solid 1px #999;}
    .menu a:link  {color:#fff;font: Arial, Helvetica, sans-serif;font-size:16px;font-weight:normal;width: 111px;height: 38px; text-decoration: none;}
    .menu .item a:visited {color:#fff;font: Arial, Helvetica, sans-serif;font-size:16px;font-weight:normal;width: 111px;height: 38px;}
    .menu .item:hover, .menu .selected{background: url(../images/images/menu_active.jpg) no-repeat;width: 111px;height: 25px;color:#fff;}
*/

.menu {
  position: relative;
}

.menu .menubarhomea {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 105px;
  height: 40px;
  background-image: url(../images/images/logo.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.menu .menubarhomea:hover {
  text-decoration: none;
}

#divMenuBreadCrumbs {
  width: 100px;
  height: 38px;
  background-color: lime;
}

.divMyAccountMenuStructure ul {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
}

.divMyAccountMenuStructure ul li {
}

.tabs {
}

.tabs ul {
  display: block;
}

.tabs ul li {
  float: left;
  padding: 5px 10px;
  border: solid 1px #ccc;
  border-bottom: none;
  margin: 0 4px 0 0;
  background: #fff;
  list-style-type: none;
}

.tabs ul li.active,
.tabs ul li:hover {
  color: #fff;
  background: #f5811c;
}

.tabs ul li a {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  color: #000;
}

.tabs .divTab {
  display: block;
  background: #f5811c;
}

.tabs .divTab .divTabInner {
  display: block;
  border: solid 10px #f5811c;
  background-color: #fff;
}

/*** SUPERFISH ESSENTIAL STYLES ***/
.menubar,
.menubar * {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menubar {
  width: 960px;
  margin: 10px 10px 15px;
}

.menubar ul {
  position: absolute;
  top: -999em;
  width: 10em; /* left offset of submenus need to match (see below) */
}

.menubar ul li {
  width: 100%;
  list-style-type: none;
}

.menubar li:hover {
  visibility: inherit; /* fixes IE7 'sticky bug' */
}

.menubar li {
  position: relative;
  float: right;
}

.menubar a {
  display: block;
  position: relative;
}

.menubar li:hover ul,
.menubar li.sfHover ul {
  top: 2.5em; /* match top ul list item height */
  left: 0;
  z-index: 99;
}

ul.menubar li:hover li ul,
ul.menubar li.sfHover li ul {
  top: -999em;
}

ul.menubar li li:hover ul,
ul.menubar li li.sfHover ul {
  top: 0;
  left: 10em; /* match ul width */
}

ul.menubar li li:hover li ul,
ul.menubar li li.sfHover li ul {
  top: -999em;
}

ul.menubar li li li:hover ul,
ul.menubar li li li.sfHover ul {
  top: 0;
  left: 10em; /* match ul width */
}

/*** DEMO SKIN ***/
.menubar {
  height: 40px;
  padding-top: 0;
  margin-bottom: 15px;
}

.menubar a {
  line-height: 40px;
  text-decoration: none;
}

.menubar a,
.menubar a:visited {
  /* visited pseudo selector so IE6 applies text colour */
  color: #fff;
}

.menubar .menubarlvl1li.menubarhome {
  width: 105px;
  border-right: none;
  border-left: none;
}

.menubarlvl1li {
  width: 120px;
  border-right: solid 1px #999;
  border-left: solid 1px #666;
  margin: 0;
  text-align: center;
}

.menubarlvl1li a {
  font-size: 16px;
}

.menubar .menubarlvl2ul {
  margin-top: 9px;
}

.menubar .menubarlvl2li,
.menubar .menubarlvl3li {
  width: inherit;
  height: inherit;
  border-right: none;
  border-right: solid 1px #555;
  border-bottom: solid 1px #555;
  border-left: none;
  border-left: solid 1px #555;
  text-align: left;
  background-color: #444;
}

.menubar .menubarlvl2li a,
.menubar .menubarlvl3li a {
  padding-left: 10px;
  font-size: 14px;
}

.menubar li:hover,
.menubar li.sfHover,
.menubar a:focus,
.menubar a:hover,
.menubar a:active {
  outline: 0;
}

.menubar .menubarlvl1li:hover,
.menubar .menubarlvl1li.sfHover {
}

.menubar .menubarlvl2li:hover,
.menubar .menubarlvl2li.sfHover,
.menubar .menubarlvl3li:hover,
.menubar .menubarlvl3li.sfHover {
  background-color: #5d5d5d;
  background-image: none;
}

.menubar .menubarlvl1li.menubarcurrent {
}

.menubar .menubarlvl2li.menubarcurrent,
.menubar .menubarlvl2li.menubarcurrent {
  background-color: #5d5d5d;
}

/*** arrows **/
.menubar a.sf-with-ul {
  min-width: 1px; /* trigger IE7 hasLayout so spans position accurately */
  padding-right: 2.25em;
}

.sf-sub-indicator {
  display: block;
  position: absolute;
  top: 1.05em; /* IE6 only */
  right: 0.75em;
  width: 10px;
  height: 10px;
  overflow: hidden;
  text-indent: -999em;
  background: url(../images/images/arrows-ffffff.png) no-repeat -10px -100px; /* 8-bit indexed alpha png. IE6 gets solid image only */
}

a > .sf-sub-indicator {
  /* give all except IE6 the correct values */
  top: 0.8em;
  background-position: 0 -100px; /* use translucent arrow for modern browsers */
}

/* apply hovers to modern browsers */
a:focus > .sf-sub-indicator,
a:hover > .sf-sub-indicator,
a:active > .sf-sub-indicator,
li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator {
  background-position: -10px -100px; /* arrow hovers for modern browsers */
}

/* point right for anchors in subs */
.menubar ul .sf-sub-indicator {
  background-position: -10px 0;
}

.menubar ul a > .sf-sub-indicator {
  background-position: 0 0;
}

/* apply hovers to modern browsers */
.menubar ul a:focus > .sf-sub-indicator,
.menubar ul a:hover > .sf-sub-indicator,
.menubar ul a:active > .sf-sub-indicator,
.menubar ul li:hover > a > .sf-sub-indicator,
.menubar ul li.sfHover > a > .sf-sub-indicator {
  background-position: -10px 0; /* arrow hovers for modern browsers */
}

/*** shadows for all but IE6 ***/
.sf-shadow ul {
  padding: 0 8px 9px 0;
  background: url(../images/images/shadow.png) no-repeat bottom right;

  /*
	    -moz-border-radius-bottomleft: 17px;
	    -moz-border-radius-topright: 17px;
	    -webkit-border-top-right-radius: 17px;
	    -webkit-border-bottom-left-radius: 17px;
    */
}

.sf-shadow ul.sf-shadow-off {
  background: transparent;
}

/*** SUPERFISH ESSENTIAL STYLES ***/
.ptiMenuBar,
.ptiMenuBar * {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ptiMenuBar {
  width: 100%;
  margin: 0;
}

.ptiMenuBar ul {
  position: absolute;
  top: -999em;
  width: 10em; /* left offset of submenus need to match (see below) */
}

.ptiMenuBar ul li {
  width: 100%;
  list-style-type: none;
}

.ptiMenuBar li:hover {
  visibility: inherit; /* fixes IE7 'sticky bug' */
}

.ptiMenuBar li {
  position: relative;
  float: left;
}

.ptiMenuBar a {
  display: block;
  position: relative;
}

.ptiMenuBar a {
  display: block;
  position: relative;
}

.ptiMenuBar li:hover ul,
.ptiMenuBar li.sfHover ul {
  top: 2.5em; /* match top ul list item height */
  left: 0;
  z-index: 99;
}

ul.ptiMenuBar li:hover li ul,
ul.ptiMenuBar li.sfHover li ul {
  top: -999em;
}

ul.ptiMenuBar li li:hover ul,
ul.ptiMenuBar li li.sfHover ul {
  top: 0;
  left: 10em; /* match ul width */
}

ul.ptiMenuBar li li:hover li ul,
ul.ptiMenuBar li li.sfHover li ul {
  top: -999em;
}

ul.ptiMenuBar li li li:hover ul,
ul.ptiMenuBar li li li.sfHover ul {
  top: 0;
  left: 10em; /* match ul width */
}

/*** DEMO SKIN ***/
.ptiMenuBarBG {
  width: 100%;
  min-height: 38px;
}

.ptiMenuBar {
  height: 38px;
  padding-top: 0;
  margin-bottom: 0;
}

.ptiMenuBar a {
  height: 38px;
  line-height: 38px;
  text-decoration: none;
}

.ptiMenuBar a,
.ptiMenuBar a:visited {
  /* visited pseudo selector so IE6 applies text colour */
  color: #fff;
}

.ptiMenuBar a span {
  white-space: nowrap;
}

.ptiMenuBarLiLvl1 {
  border-right: solid 1px #fff;
  border-left: none; /* solid 1px #666; */
  margin: 0;
  text-align: center;
}

.ptiMenuBarLiLvl1 a {
  padding-right: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.ptiMenuBar .ptiMenuBarUlLvl2 {
  margin-top: 0;
}

.ptiMenuBar .ptiMenuBarUlLvl3 {
  margin-top: -1px;
}

.ptiMenuBar .ptiMenuBarLiLvl2,
.ptiMenuBar .ptiMenuBarLiLvl3 {
  width: inherit;
  height: inherit;

  /*
    border-right: solid 1px #999;
    border-left: solid 1px #999;
*/
  border-top: solid 1px #fff;
  border-right: none;
  border-left: none;
  margin-left: 0;
  text-align: left;
  background-color: #444;
}

.ptiMenuBar .ptiMenuBarLiLvl2 a,
.ptiMenuBar .ptiMenuBarLiLvl3 a {
  padding-left: 10px;
  font-size: 14px;
}

.ptiMenuBar li:hover,
.ptiMenuBar li.sfHover,
.ptiMenuBar a:focus,
.ptiMenuBar a:hover,
.ptiMenuBar a:active {
  outline: 0;
}

/*** arrows **/
.ptiMenuBar a.sf-with-ul {
  min-width: 1px; /* trigger IE7 hasLayout so spans position accurately */
  padding-right: 2.25em;
}

.sf-sub-indicator {
  display: block;
  position: absolute;
  top: 14px;
  right: 5px;
  width: 10px;
  height: 10px;
  overflow: hidden;
  text-indent: -999em;
  background: url(../images/images/arrows-ffffff.png) no-repeat -10px -100px; /* 8-bit indexed alpha png. IE6 gets solid image only */
}

a > .sf-sub-indicator {
  /* give all except IE6 the correct values */
  top: 14px;
  background-position: 0 -100px; /* use translucent arrow for modern browsers */
}

/* apply hovers to modern browsers */
a:focus > .sf-sub-indicator,
a:hover > .sf-sub-indicator,
a:active > .sf-sub-indicator,
li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator {
  background-position: -10px -100px; /* arrow hovers for modern browsers */
}

/* point right for anchors in subs */
.ptiMenuBar ul .sf-sub-indicator {
  background-position: -10px 0;
}

.ptiMenuBar ul a > .sf-sub-indicator {
  background-position: 0 0;
}

/* apply hovers to modern browsers */
.ptiMenuBar ul a:focus > .sf-sub-indicator,
.ptiMenuBar ul a:hover > .sf-sub-indicator,
.ptiMenuBar ul a:active > .sf-sub-indicator,
.ptiMenuBar ul li:hover > a > .sf-sub-indicator,
.ptiMenuBar ul li.sfHover > a > .sf-sub-indicator {
  background-position: -10px 0; /* arrow hovers for modern browsers */
}

.ptiMenuBar .ptiMenuBarLiLvl1.ptiMenuBarAddNew a span,
.ptiMenuBar .ptiMenuBarLiLvl2.ptiMenuBarAddNew a span,
.ptiMenuBar .ptiMenuBarLiLvl3.ptiMenuBarAddNew a span {
  padding-right: 5px;
  padding-left: 5px;
  border: solid 1px #693;
  color: #fff;
  background-color: #693;
  text-transform: none;
}

.ptiMenuBar .ptiMenuBarLiLvl1.ptiMenuBarAddNew a span:hover,
.ptiMenuBar .ptiMenuBarLiLvl2.ptiMenuBarAddNew a span:hover,
.ptiMenuBar .ptiMenuBarLiLvl3.ptiMenuBarAddNew a span:hover {
  cursor: pointer;
}

.submenu {
  width: 920px;
  height: 31px;
  padding-top: 2px;
  padding-left: 10px;
  margin: 14px 0 0 26px;
  background-color: #fff;
}

.submenu .item {
  height: 28px;
  float: left;
  background: url(../images/images/submenu_line.png) top right no-repeat;
}

.submenu .itemlink {
  height: 28px;
  float: left;
  padding: 7px 10px;
  margin-right: 10px;
}

.submenu .itemimage {
  background-image: none;
}

.submenu .itemimage a img {
  margin: 0 5px;
}

.submenu .itemright {
  height: 28px;
  float: right;
  background: url(../images/images/submenu_line.png) top left no-repeat;
}

.submenu .itemrightlink {
  height: 28px;
  padding: 0 10px;
  margin-left: 10px;
}

.submenu .itemrightimage {
  margin-right: 5px;
  background-image: none;
}

.submenu .item .itemlink,
.submenu .itemright .itemrightlink {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 12px;
  line-height: 28px;
  color: #000;
}

.submenu .item .itemlink:hover,
.submenu .itemright .itemrightlink:hover {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 12px;
  line-height: 28px;
  text-decoration: none;
  color: #458ed6;
}

.submenu .pagetitle {
  height: 28px;
  height: 40px;
  float: left;
  padding: 0 0 0 15px;
  border-left: solid 1px #fff;
  margin: 0;
  line-height: 28px;
  line-height: 40px;
}

.submenu .pagetitle .pagetitleDelimiter {
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  color: #fff;
  text-transform: none;
}

.submenu .pagetitle .pagetitleText {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  color: #fff;
  text-transform: none;
}

.submenu .pagetitle .pagetitleUrl {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  line-height: 40px;
  text-decoration: none;
  color: #fff;
  text-transform: none;
}

.submenu .pagetitle .pagetitleUrl:hover {
  text-decoration: underline;
}

#divMenuAjaxLoading {
  display: none;
  padding-right: 20px;
  text-align: right;
  color: #458ed6;
  background: transparent url(../images/images/AjaxLoader.gif) right center no-repeat;
}

#imgPreviewContainer {
  z-index: 1000;
}

/* Edit Online Mag Page */
.tdPageXofY {
  padding-right: 20px;
  padding-left: 20px;
}

#divPageOuter {
  display: block;
  border: solid 1px #ccc;
  margin: 10px auto 1px;
  background-color: #eee;
}

#divPageOuter.loading {
  background-image: url(../images/images/AjaxLoader.gif);
  background-position: center center;
  background-repeat: no-repeat;
}

#divPageControls {
  position: relative;
}

#divPageControls .ControlsRow {
  position: absolute;
  top: 0;
  right: 0;
}

#divPage .PageContainer {
  margin: 10px auto;
}

#divPage .ptiForm {
  border-spacing: 0;
}

#divPage .ptiForm td {
  padding: 1px;
  background-color: #fff;
}

#divPage .ptiForm .TextLabel {
  padding-right: 30px;
  line-height: 20px;
  text-align: right;
  background-color: #fafafc;
}

#divPage .Listing td,
#divPage .Listing th {
  padding: 1px;
}

.tinymcePopupContainer {
  position: relative;
}

.tinymcePopupContainer .ControlsRowTinyMCE {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 0;
  text-align: center;
}

textarea.tinymcePopup {
  display: none;
}

#ulModuleMenu {
  width: 250px;
  overflow: hidden;
}

#ulModuleMenu li {
  margin-left: 15px;
  list-style-type: none;
}

#ulModuleMenu li span,
#ulModuleMenu li a {
  font-size: 12px;
  line-height: 18px;
}

#ulModuleMenu li.selected a {
  color: #f5811c;
}

#ulModuleMenu li ul li {
  margin-left: 30px;
  list-style-type: circle;
}

#ulModuleMenu li ul li span,
#ulModuleMenu li ul li a {
  font-size: 12px;
  line-height: 18px;
}

#ulModuleMenu li ul li.selected a {
  color: #f5811c;
}

#divResources {
  height: 500px;
  overflow: auto;
  border: solid 1px #ccc;
  background: #333;
}

#divResources .ResourcesContainer {
  margin: 10px;
}

.divModuleLinkArea {
  opacity: 0.5;
  overflow: hidden;
  background-color: #458ed6;
}

.divModuleLinkArea span {
  position: relative;
  color: #fff;
  cursor: pointer;
}

.resizableSelected {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  filter: alpha(opacity=80);
}

.recurring-icon {
  background: url(../images/repeat.svg) bottom / contain no-repeat;
  position: relative;
  top: 2px;
}

.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.progressWrapper {
  width: 357px;
  overflow: hidden;
}

.progressContainer {
  overflow: hidden;
  padding: 4px;
  border: solid 1px #e8e8e8;
  margin: 5px;
  background-color: #f7f7f7;
}

/* Message */
.message {
  overflow: hidden;
  padding: 10px 20px;
  border: solid 1px #fd9;
  margin: 1em 0;
  background-color: #ffc;
}

/* Error */
.red {
  padding: 0.7rem;
  background-color: #ffebeb;
}

/* Current */
.green {
  border: solid 1px #ddf0dd;
  background-color: #ebffeb;
}

/* Complete */
.blue {
  border: solid 1px #cee2f2;
  background-color: #f0f5ff;
}

.progressName {
  width: 323px;
  height: 14px;
  overflow: hidden;
  font-size: 8pt;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  color: #555;
}

.progressBarInProgress,
.progressBarComplete,
.progressBarError {
  width: 0%;
  height: 2px;
  margin-top: 2px;
  font-size: 0;
  background-color: blue;
}

.progressBarComplete {
  width: 100%;
  background-color: green;
  visibility: hidden;
}

.progressBarError {
  width: 100%;
  background-color: red;
  visibility: hidden;
}

.progressBarStatus {
  width: 337px;
  margin-top: 2px;
  font-family: Arial;
  font-size: 7pt;
  text-align: left;
  white-space: nowrap;
}

a.progressCancel {
  display: block;
  width: 14px;
  height: 14px;
  float: right;
  font-size: 0;
  background-position: -14px 0;
  background-repeat: no-repeat;
}

/* Listing */
.Listing {
  width: 100%;
  border: 0;
  border-collapse: collapse;
}

.Listing td,
.Listing th,
.Listing .ListingHeader td {
  padding: 12px 7px;
  line-height: 18px;
}

.Listing hr {
  border-color: #e8e8e8;
}

.ListingCompact td {
  padding: 1px;
  line-height: 16px;
}

.searchContainer{
  padding-bottom:2rem;
}

.InlineEditRow td{
  height:5.5rem;
  vertical-align: inherit;
  line-height: normal;
}

.Listing tr.OddRow,
.Listing tr.EvenRow,
.Listing tr.SensitiveRow,
.Listing tr.OverdueRow,
.Listing tr.HighlightRow,
.Listing tr.InvalidRow,
.Listing tr.ArchivedRow,
.Listing tr.PTOnlyRow {
  border-bottom: 1px solid #e8e8e8;
}

.Listing tr.OddRow {
  background-color: #fff !important;
}

.Listing tr.EvenRow {
  background-color: #fff !important;
}

.Listing tr.NewRow {
  background-color: #dff0d8 !important;
}

.Listing tr.over {
  background-color: #fff;
}

.Listing .SensitiveRow,
.Listing .OverdueRow {
  background-color: #f7e0e0;
}

.Listing tr.HighlightRow {
  background-color: var(--pageTigerOnlyBG);
}

.Listing tr.InvalidRow {
  background-color: #fff8f8;
}

.Listing tr.ArchivedRow {
  background-color: #fff8f8;
}

.Listing tr.PTOnlyRow {
  border-bottom: 1px solid #e8e3cc;
  background-color: #fcf8e3;
}

.Listing tr.TotalRow {
  background-color: #458ed6;
}

.Listing tr.ButtonRow {
  background-color: #f3f3f3;
}

.Listing td.PositiveCell {
  font-weight: bold;
  color: #693;
}

.Listing td.NegativeCell {
  font-weight: bold;
  color: #c00;
}

ul.Listing li.OddRow {
  background-color: #fff !important;
}

ul.Listing li.EvenRow {
  background-color: #fafafc !important;
}

ul.Listing li {
  border-bottom: solid 1px #ddd;
}

.ListingWithHover tr:hover {
  background-color: #eee !important;
}

.ButtonRow input {
  padding: 1px;
}

.SpacerRow {
  background-color: #fff;
}

.ListingUnread {
  font-weight: bold;
}

.over td {
  border: solid 1px #ddd;
}

.ArchivedRow td,
td .ArchivedRow td {
  border-bottom: solid 1px #f7dcdc;
}

.TotalRow td,
td .TotalRow td {
  border: solid 1px #ddd;
  font-weight: bold;
  color: #fff;
}

.ButtonRow td,
td .ButtonRow td {
  border: solid 1px #ddd;
}

.PTIAdminRow {
  background-color: #f7f1d1;
}

tr.PTIAdminRow td {
  background-color: #fcf8e3;
}

/* .PTIDevRow {
  background-color: #fff8f8;
} */
tr.PTIDevRow td {
  background-color: #ffdfdf;
}

.ListingHeader {
  background-color: #ddd;
}

.ListingHeader td,
.ListingHeader th {
  border: solid 1px #ddd;

  /* text-align: center; */
  font-weight: bold;
  color: #333;
  background-color: #ddd;
}

.ListingHeader .left {
  text-align: left;
}

.ListingHeader .center {
  text-align: center;
}

.ListingHeader .right {
  text-align: right;
}

.ListingHeader td a:link,
.ListingHeader td a:visited,
.ListingHeader th a:link,
.ListingHeader th a:visited {
  color: #333;
}

.ListingHeader td a:hover,
.ListingHeader td a:active,
.ListingHeader th a:hover,
.ListingHeader th a:active {
  color: #c00;
  cursor: hand;
}

.Listing .ListingHeaderBig td,
.Listing .ListingHeaderBig th {
  font-size: 20px;
  line-height: 40px;
}

td.LisingHeaderGroup1 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #888;
}

td.LisingHeaderGroup1-1 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #888;
}

td.LisingHeaderGroup1-2 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #888;
}

td.LisingHeaderGroup2 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #666;
}

td.LisingHeaderGroup2-1 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #666;
}

td.LisingHeaderGroup2-2 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #666;
}

td.LisingHeaderGroup3 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #444;
}

td.LisingHeaderGroup3-1 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #444;
}

td.LisingHeaderGroup3-2 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #444;
}

td.LisingHeaderGroup4 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #222;
}

td.LisingHeaderGroup5 {
  border: solid 1px #ccc;
  text-align: center;
  color: #f1f1f1;
  background-color: #555;
}

.NoRecordsFoundRow,
.divNoRecordsFoundRow {
  border: 1px solid #c3c3c3;
  border-radius: 0.4rem;
  background: #fff;
}

.NoRecordsFoundRow td,
.divNoRecordsFoundRow {
  padding: 30px 0;
  font-size: 1.5rem;
  text-align: center;
  color: #458ed6;
}

.divNoRecordsFoundRow.inside-issue-container {
  width: 100%;
  margin: 0 2rem;
}

.LisingHeaderGroup0 td {
  padding: 20px 10px 10px;
  border: solid 1px #ccc;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
}

.LisingHeaderGroup0 td a {
  font-size: 16px;
  font-weight: bold;
}

.NoRecordsFoundRow {
  border: solid 1px #ccc;
  text-align: center;
  background-color: #fff;
  vertical-align: middle;
}

.NoBorder {
  border: solid 1px #fff;
}

.SubTotal1Row {
  background-color: #fdf5e6;
}

.SubTotal2Row {
  background-color: #fdf5e6;
}

.SubTotal3Row {
  background-color: #fdf5e6;
}

.SubTotal4Row {
  background-color: #fdf5e6;
}

.SubTotal5Row {
  background-color: #fdf5e6;
}

.TotalRow {
  background-color: #fdf5e6;
}

.SizeGreen,
td.SizeGreen {
  background-color: #b2ffb2;
}

.SizeAmber,
td.SizeAmber {
  background-color: #ffe0b2;
}

.SizeRed,
td.SizeRed {
  background-color: #ffb2b2;
}

/* a.AllIssues {
  color: #fff;
  text-decoration: underline;
} */

span.pricingCredits {
  font-size: 32px;
  line-height: 64px;
}

span.price {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

span.priceFree {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

span.priceDiscounted {
  font-size: 14px;
  text-decoration: line-through;
  color: #666;
}

span.priceRecurring {
  font-size: 12px;
  font-style: italic;
}

span.priceNoOfPayments {
  font-size: 12px;
  font-style: italic;
}

input.pricePurchase {
  margin-top: 6px;
  margin-bottom: 3px;
}

span.ImageLibraryPricingCredits {
  font-size: 32px;
  line-height: 64px;
}

span.ImageLibraryPrice {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

span.ImageLibraryPriceFree {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

span.ImageLibraryPriceDiscounted {
  font-size: 14px;
  text-decoration: line-through;
  color: #666;
}

span.ImageLibraryPriceRecurring {
  font-size: 12px;
  font-style: italic;
}

span.ImageLibraryPriceNoOfPayments {
  font-size: 12px;
  font-style: italic;
}

input.ImageLibraryPricePurchase {
  margin-top: 6px;
  margin-bottom: 3px;
}

td.subscriptionFooter1 {
  padding: 0;
  margin: 0;
}

td.subscriptionFooter2 {
  padding: 0;
  margin: 0;
}

td.subscriptionFooter3 {
  padding: 0;
  margin: 0;
}

td.subscriptionFooter4 {
  padding: 0;
  margin: 0;
}

td.subscriptionShowFeatures a {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

div.subscriptionFooterImage {
  overflow: hidden;
}

.ReportContainer {
  max-height: 300px;
  overflow: auto;
  border: solid 1px #ccc;
  margin: 20px 0 5px 0;
  background-color: #999;
}

.ReportBlockContainer {
  margin-bottom:20px;
}

.ReportContainerExpanded {
  overflow: auto;
  border: solid 1px #ccc;
  margin: 20px 0 20px 0;
  background-color: #999;
}

.DistributeIcons a img {
  margin: 10px 22px 0 0;
}

.divErrorPageContainer {
  width: 500px;
  border: solid 10px #f5811c;
  margin: 100px auto 0;
}

.divErrorPageContainer div {
  margin: 20px;
}

.divErrorPageContainer div h1 {
  padding: 0;
  margin: 30px 0;
  font-size: 26px;
  line-height: 30px;
}

.divErrorPageContainer div p {
  font-size: 16px;
  line-height: 26px;
}

.divErrorPageContainer div p a {
  font-size: 16px;
  line-height: 26px;
}

.ptiForm td ul.SecuritySummaryMarkup {
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.ptiForm td ul.SecuritySummaryMarkup li {
  font-size: 14px;
  color: #333;
}

#divContainerInner.Reporting {
  width: 930px;
  margin: 0 15px;
}

#divContainerInner.Reporting .ReportSuites {
  display: none;
  height: 20px;
  margin: 10px 5px 0;
  line-height: 20px;
  text-align: right;
}

#divContainerInner.Reporting .ReportSuites a.Suite {
  font-size: 12px;
}

#divContainerInner.Reporting .ReportSuites span.Suite {
  font-size: 12px;
}

#divContainerInner.Reporting .ReportSuites .SuiteSep {
  padding: 0 10px;
  font-size: 12px;
}

/* stylelint-enable */
.filter-container {
  display: flex;
}

.filter-summary {
  display: flex;
}

.filter-item {
  display: flex;
  padding: 0.7rem;
  border: 0.1rem solid #d8d8d8;
  border-radius: 0.4rem;
  margin-left: 2rem;
}

.filter-item.mod-active {
  border-color: #a1cdff;
  background: #d3eaff;
}

.filter-name {
  padding-right: 1rem;
  font-weight: bold;
  color: #505050;
}

.filter-item-clear-link {
  width: 1.4rem;
  height: 100%;
  margin-left: 1.1rem;
  background: url(../images/cross.svg#lightblue) no-repeat center / contain;
}

.filter-item-clear-link:--focus-hover {
  background: url(../images/cross.svg#darkblue) no-repeat center / contain;
  cursor: pointer;
}

/* stylelint-disable */
#divContainerInner.Reporting #pnlFilters {
  padding: 5px;
  border: none;
  margin: 5px;
  background-color: #eee;
}

#divContainerInner.Reporting .ReportBreadCrumbs {
  height: 30px;
  margin: 10px 5px;
  line-height: 30px;
}

#divContainerInner.Reporting .ReportBreadCrumbs a.BreadCrumb {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#divContainerInner.Reporting .ReportBreadCrumbs span.BreadCrumb {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#divContainerInner.Reporting .ReportBreadCrumbs .BreadCrumbSep {
  padding: 0 2px;
  font-size: 14px;
  color: #999;
}

.ReportingParams .ParamFieldUtcDateTypeDate {
  margin-right: 5px;
}

.ReportingParams .ParamFieldUtcDateTypeHour {
  margin-right: 5px;
}

.ReportingParams .SaveButton {
  margin-left: 15px;
}

#divContainerInner.Reporting h2 {
  border: none;
  line-height: 20px;
}

.ReportPageNameDesc {
  padding: 10px;
  margin: 5px;
  background-color: #eee;
}

.ReportPageNameDesc .ReportPageName h1 {
  padding: 0;
  margin: 5px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #333;
}

.ReportPageNameDesc .ReportPageName h1 span {
  padding: 0;
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #333;
}

.ReportSection h2 {
  border: none;
  line-height: 20px;
}

.ReportSection .ReportSectionNameDesc .ReportSectionName {
  margin: 0 5px;
}

.ReportSection .ReportSectionNameDesc .ReportSectionDesc {
  margin: 0 5px;
}

.ReportSection .ReportRequiredReportParams {
  display: block;
  margin: 10px;
  padding: 50px;
  text-align: center;

  border: 1px solid #d6d6d6;
  border-radius: var(--borderRadius);
  box-shadow: 0 0.1rem 0.6rem #d4d4d4;
}

.ReportCard {
  display: block;
  position: relative;
  float: left;
  background-color: #fff;
  margin: 10px;

  border: 1px solid #d6d6d6;
  border-radius: var(--borderRadius);
  box-shadow: 0 0.1rem 0.6rem #d4d4d4;
}


.ReportCard.ReportCardWidth25 {
  width: calc(25% - 20px);
}

.ReportCard.ReportCardWidth33 {
  width: calc(33.33% - 20px);
}

.ReportCard.ReportCardWidth50 {
  width: calc(50% - 20px);
}

.ReportCard.ReportCardWidth66 {
  width: calc(66.66% - 20px);
}

.ReportCard.ReportCardWidth75 {
  width: calc(75% - 20px);
}

.ReportCard.ReportCardWidth100 {
  width: calc(100% - 20px);
}

@media (max-width: 800px) {
  .ReportCard.ReportCardWidth25,
  .ReportCard.ReportCardWidth33,
  .ReportCard.ReportCardWidth50,
  .ReportCard.ReportCardWidth66,
  .ReportCard.ReportCardWidth75,
  .ReportCard.ReportCardWidth100 {
    width: 100%;
  }
}

.ReportCard .ReportCardInner {
  position: relative;
  overflow: auto;
  margin: 10px;
}

.ReportCard.ReportCardHeight100 .ReportCardInner {
  height: 100px;
}

.ReportCard.ReportCardHeight150 .ReportCardInner {
  height: 150px;
}

.ReportCard.ReportCardHeight200 .ReportCardInner {
  height: 200px;
}

.ReportCard.ReportCardHeight250 .ReportCardInner {
  height: 250px;
}

.ReportCard.ReportCardHeight300 .ReportCardInner {
  height: 300px;
}

.ReportCard.ReportCardHeight350 .ReportCardInner {
  height: 350px;
}

.ReportCard.ReportCardHeight400 .ReportCardInner {
  height: 400px;
}

.ReportCard.ReportCardHeight0 .ReportCardInner {
  min-height: 100px;
}


.ReportCard .ReportCardInner h2{
  font-size: 18px;
  color: #333;
}

.ReportCardTable {
  width: 100%;
  border: 0;
  border-collapse: collapse;
}

.ReportCardTable tr {
  background-color: #fff;
}

.ReportCardTable tr th,
.ReportCardTable tr td {
  border-bottom: solid 1px #ddd;
  line-height: 24px;
}

.ReportCardTable tr th {
  padding: 0 5px;
  text-align: center;
  color: #333;
}

.ReportCardTable tr th span {
  font-weight: bold;
}


.ReportCardTable tr.Odd {
  background-color: #fff;
}

.ReportCardTable tr.Even {
  background-color: #fff;
}


.ReportCardTable tr.Highlight {
  background-color: var(--pageTigerOnlyBG);
}

.ReportCardTable tr.Overdue {
  background-color: #fff8f8;
}

.ReportCardTable tr.New {
  background-color: #dff0d8;
}

.ReportCardTable tr td {
  padding: 0 5px;
}

.ReportCardTable tr td.RepeatedData {
  text-align: center;
}

.ReportCardTable .NoRecordsFoundRow td {
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  color: #458ed6;
} /* Same as H3 tag */
.ReportCardTable .NoRecordsFoundRow {
  border: solid 1px #ccc;
  text-align: center;
  background-color: #fff;
  vertical-align: middle;
}

.ReportCardTable tr td.Kpi {
  padding-right: 10px;
  padding-left: 10px;
}

.ReportCardTable tr td.Kpi span {
  font-size: 16px;
}

.ReportCardTable tr td.KpiPassed {
  padding-right: 20px;
  /* background: url(/Reporting/DashboardGreen.gif) right 5px center no-repeat; */
}

.ReportCardTable tr td.KpiFailed {
  padding-right: 20px;
  /* background: url(/Reporting/DashboardRed.gif) right 5px center no-repeat; */
}

.ReportCardTable tr.Highlight td span.Highlight {
  padding: 0;
  padding: 0 10px;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.ReportCardFooter {
  margin-bottom: 10px;
}

.ReportCard .ReportCardFooter .ReportCardFooterPaging a {
  background-color: var(--tigerGrey);
  border-radius: var(--borderRadius);

  padding: 10px;
}

.ReportCard .ReportCardFooter .ReportCardFooterPaging a:--focus-hover {
  background-color: var(--tigerGreyHover);
}



.ReportCard .ReportCardFooter .ReportCardFooterPaging a span {
  color: #fff;
}

.ReportCard .ReportCardFooter .ReportCardFooterPaging .ReportCardFooterRecordsCount {
  font-weight: bold;
}

.ReportCard .ReportCardFooter .ReportCardFooterDownload {
  width: 1%;
}

.ReportCard .ReportCardFooter .ReportCardFooterKPI {
  width: 1%;
}

.ReportCard .ReportCardFooter .ReportCardFooterDesign {
  width: 1%;
}

.ReportCard .ReportCardFooter .ReportCardFooterManage {
  text-align: center;
}

.ReportCard .ReportCardFooter .ReportCardFooterView {
  width: 1%;
  text-align: right;
}


.ReportCard .ReportCardFooter tr td span {
  margin-right: 5px;
  margin-left: 5px;
  line-height: 24px;
}

.ReportCard .ReportCardFooter tr td a span {
  margin-right: 0;
  margin-left: 0;
}

.ReportCard .ReportCardFooter tr td a {
  margin-right: 5px;
  margin-left: 5px;
  line-height: 24px;
}

.ReportCardSqlHtmlCount {
  margin-top: 50px;
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.ReportCardSqlHtmlLabel {
  font-size: 32px;
  font-weight: normal;
}

.ReportCard .ReportCardInner .ReportingIssueThumb {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #d6d6d6;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .ReportingIssueThumbLink {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain
}

.ReportCard .ReportCardInner .ReportingIssueThumb .ReportingIssueThumbLink span {
  display: none;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .spanCoverPublished {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 0;
  text-align: right;
  color: #fff;
  background-color: #f5811c;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .spanCoverScheduled {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 0;
  text-align: right;
  color: #eee;
  background-color: #693;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .ReportingIssueThumbFooter {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 45px;
  line-height: 45px;
  background-color: #eee;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .ReportingIssueThumbFooter .Design {
  position: absolute;
  top: 0;
  left: 10px;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .ReportingIssueThumbFooter .Manage {
  text-align: center;
}

.ReportCard .ReportCardInner .ReportingIssueThumb .ReportingIssueThumbFooter .View {
  position: absolute;
  top: 0;
  right: 10px;
}

#txtSearch {
  margin-right: 20px;
}

#ddlClientGroup {
  min-width: 100px;
  margin-right: 10px;
}

.tdBadgeAwardedFor {
  min-width: 230px;
}

.dlBadgeAwardedFor dt {
  display: block;
  width: 80px;
  float: left;
  clear: left;
  font-weight: bold;
  line-height: 20px;
}

.dlBadgeAwardedFor dd {
  display: block;
  width: 30rem;
  float: left;
  overflow: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
}

.tdBadgePlaceholders {
  min-width: 230px;
}

.dlBadgePlaceholders dt {
  display: block;
  width: 17rem;
  float: left;
  clear: left;
  font-weight: bold;
  line-height: 20px;
}

.dlBadgePlaceholders dd {
  display: block;
  width: 30rem;
  float: left;
  overflow: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
}

.PublicationListItem {
  width: 175px;
  float: left;
  border: solid 1px #ccc;
  margin: 5px 10px 5px 0;
}

.PublicationListItem5 {
  margin-right: 0;
}

.PublicationListItem .Cover {
  position: relative;
  width: 100%;
  height: 170px;
  border: none;
  background-color: #999;
  background-position: center;
  background-repeat: no-repeat;
}

.PublicationListItem .Cover .Published {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 0;
  font-size: 13px;
  text-align: right;
  color: #fff;
  background-color: #f5811c;
}

.PublicationListItem .Cover .Scheduled {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 0;
  text-align: right;
  color: #eee;
  background-color: #693;
}

.PublicationListItem .Desc {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  border-bottom: solid 1px #ccc;
  margin: 0;
}

.PublicationListItem .Desc h2 {
  padding: 5px 5px 0;
  border-bottom: none;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  color: #333;
  background: none;
  text-transform: none;
}

.PublicationListItem .Links {
  position: relative;
  width: 100%;
  height: 24px;
  margin: 0 auto;
  font-size: 13px;
  line-height: 24px;
}

.PublicationListItem .Links .Manage {
  position: absolute;
  top: 0;
  left: 10px;
}

.PublicationListItem .Links .View {
  position: absolute;
  top: 0;
  right: 10px;
}

.uploadProgressSection {
  width: 600px;
  margin: 150px auto;
}

.uploadProgressBarOuter {
  margin: 10px 0;
}

.uploadProgressBar {
  height: 10px;
  background-color: #f5811c;
}

.uploadProgress {
  position: relative;
  height: 14px;
  padding: 0;
  margin: 0;
  line-height: 14px;
}

.uploadProgressText {
  height: 14px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
}

.uploadProgressPercentage {
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
}

#pnlTigerLabsThumbs a {
  display: block;
  float: left;
  margin: 5px;
}

#divTigerLabs {
  max-width: 35.5rem;
}

@media (max-width: 900px) {
  #divTigerLabs {
    max-width: 100%;
  }
}

#divContainerInner {
  min-height: 500px;
  margin: 0 10px;
}

.menu .menubarhomea {
  background-image: url(../images/images/logo.png);
}

.menubar {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  background-color: #1d1d1b;
}

.menubarlvl1li {
  width: auto;
  border-right: none;
  border-left: none;
}

.menubar .menubarlvl1li:hover,
.menubar .menubarlvl1li.sfHover {
  background-image: none;
}

.menubar .menubarlvl1li.menubarcurrent a {
  color: #f5811c;
}

.menubar .menubarlvl1li.menubarcurrent .menubarlvl2ul .menubarlvl2li a {
  color: #fff;
}

.menubar .menubarlvl1li a {
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14px;
  text-transform: uppercase;
}

.menubar .menubarlvl2li a {
  font-size: 14px;
  text-transform: uppercase;
}

.menubar .menubarlvl3li a {
  font-size: 14px;
  text-transform: uppercase;
}

.menubar .menubarlvl1li a:hover,
.menubar .menubarlvl2li a:hover,
.menubar .menubarlvl3li a:hover {
  color: #f5811c;
}

.sf-sub-indicator {
  background-image: url(../images/images/arrows-ffffff.png);
}

.sf-shadow ul {
  background-image: url(../images/images/shadow.png);
}

.submenu {
  width: 960px;
  height: 40px;
  padding: 0;
  margin: 0;
  line-height: 40px;
  background-color: #f5811c;
}

.submenu .item {
  height: 40px;
  padding: 0;
  margin: 0;
  line-height: 40px;
}

.submenu .itemright {
  height: 40px;
  padding: 0;
  border-left: solid 1px #fff;
  margin: 0;
  line-height: 40px;
  background-image: none;
}

.submenu .itemrightimage {
  padding: 0;
  margin: 0;
  background-image: none;
}

.submenu .item .itemlink,
.submenu .itemright .itemrightlink {
  height: 40px;
  padding: 0 10px;
  margin: 0;
  font-size: 14px;
  line-height: 40px;
  color: #fff;
  text-transform: none;
}

.submenu .item .itemlink:hover,
.submenu .itemright .itemrightlink:hover {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-decoration: underline;
  color: #fff;
  text-transform: none;
}

/* #divMenuAjaxLoading {
  background-image: url(../images/images/ajax-loader-submenu.gif);
  height: 40px;
  line-height: 40px;
  border-left: none;
  margin-right: 10px;
} */

/* .footer {
  height: inherit;
}

.foot_l {
  float: none;
  width: auto;
  padding: 0;
  margin: 20px 0 0px 0px;
  border-top: solid 2px #f5811c;
  border-bottom: solid 2px #f5811c;
}
.foot_l p {
  line-height: 40px;
  padding: 0;
  margin: 0;
  text-align: center;
}
.foot_l a {
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  padding: 0 3px;
  color: #1d1d1b;
}
.foot_l a:hover {
  height: 40px;
  line-height: 40px;
  text-decoration: underline;
}

.foot_r {
  float: none;
  height: 40px;
  width: auto;
  padding: 0;
  margin: 0px 0 10px 0px;
  text-align: center;
}
.foot_r p {
  line-height: 40px;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #999;
} */

/* .powered_by {
  background-image: none;
}
.powered_by:hover {
  background-image: none;
} */

/* Edit Online Mag Page */
/* #divPageOuter.loading {
  background-image: url(../images/images/AjaxLoader.gif);
}
#divModuleContainer.loading {
  background-image: url(../images/images/AjaxLoader.gif);
} */

td.subscriptionHeader1 {
  background-color: #c0c1c3;
}

td.subscriptionHeader2 {
  background-color: #c0c1c3;
}

td.subscriptionHeader3 {
  background-color: #c0c1c3;
}

td.subscriptionHeader4 {
  background-color: #f5811c;
}

td.subscriptionHeader5 {
  background-color: #c0c1c3;
}

td.subscriptionHeader6 {
  background-color: #c0c1c3;
}

td.subscriptionDetail4 {
  background-color: #f5811c;
}

.pricingOtherProductsHeaderPageTiger {
  background-image: url(../images/images/04-00_Pricing-pagetiger.jpg);
}

.pricingOtherProductsHeaderTigerDesigner {
  background-image: url(../images/images/04-00_Pricing-tigerdesigner.jpg);
}

/*
.pricingOtherProductsHeaderTigerMailer {
  background-image: url(../images/images/icon_pricing-mailer.gif);
}
 */
.pricingOtherProductsHeaderTigerCreator {
  background-image: url(../images/images/04-00_Pricing-tigercreator.jpg);
}

.pricingOtherProductsHeaderTigerComplete {
  background-image: url(../images/images/04-00_Pricing-tigercomplete.jpg);
}

.pricingOtherProductsHeaderTigerBuilder {
  background-image: url(../images/images/04-00_Pricing-tigerbuilder.jpg);
}

.pricingOtherProductsHeaderOther {
  background-image: url(../images/images/04-00_Pricing-other.jpg);
}

.pricingOtherProductsHeaderTigerTraining {
  background-image: url(../images/images/04-00_Pricing-tigertraining.jpg);
}

.pricingOtherProductsHeaderTigerCreative {
  background-image: url(../images/images/04-00_Pricing-tigercreative.jpg);
}

.pricingOtherProductsHeaderTigerTokens {
  background-image: url(../images/images/04-00_Pricing-tigertokens.jpg);
}

.PlusFeature .PlusBlank {
  background-image: url(../images/images/PlusFeature.png);
}

.EnterpriseFeature .EnterpriseBlank {
  background-image: url(../images/images/EnterpriseFeature.png);
}

.EnterprisePlusFeature .EnterprisePlusBlank {
  background-image: url(../images/images/EnterprisePlusFeature.png);
}

.IssueThumbIcon.IssueThumbIconsTigerKit {
  background: url(../images/images/ptTigerKitOver.png);
}

.IssueThumbIcon.IssueThumbIconsFromTigerKit {
  background: url(../images/images/ptFromTigerKitOver.png);
}

.IssueThumbIcon.IssueThumbIconsTigerGenius {
  background: url(../images/images/ptTigerGeniusOver.png);
}

.IssueThumbIcon.IssueThumbIconsTemplate {
  background: url(../images/images/pttemplateover.png);
}

.IssueThumbIcon.IssueThumbIconsUpdated {
  background: url(../images/images/ptrefresh.png);
}

/* .divDesignerDoc .imgNewDesign {
  background-image: url(../images/images/createblankdesign.png);
} */

#tblProducts tr td.QuoteProductCell {
  height: 100px;
  padding-left: 100px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 80px;
}

/* User Licenses */
#tblProducts tr td.QuoteProductCell1613 {
  background-image: url(../images/icons/icon_pricing_users.svg);
}

/* Content Token */
#tblProducts tr td.QuoteProductCell1638 {
  background-image: url(../images/icons/icon_pricing_token_content.svg);
}

/* Content Token 50 */
#tblProducts tr td.QuoteProductCell1771 {
  background-image: url(../images/icons/icon_pricing_token_content.svg);
}

/* Studio Time (One Day) */
#tblProducts tr td.QuoteProductCell1615 {
  background-image: url(../images/icons/icon_pricing_creative.svg);
}

/* Studio Time (One Day) Special Rate */
#tblProducts tr td.QuoteProductCell1716 {
  background-image: url(../images/icons/icon_pricing_creative.svg);
}

/* Studio Time (One Day) Special Rate 2 */
#tblProducts tr td.QuoteProductCell1769 {
  background-image: url(../images/icons/icon_pricing_creative.svg);
}


/* Training Token */
#tblProducts tr td.QuoteProductCell1606 {
  background-image: url(../images/icons/icon_pricing_token_training.svg);
}

/* Service Charge - Essentials */
#tblProducts tr td.QuoteProductCell1714 {
  background-image: url(../images/icons/icon_pricing_sc_essentials.svg);
}

/* Service Charge - EssentialsPlus */
#tblProducts tr td.QuoteProductCell1756 {
  background-image: url(../images/icons/icon_pricing_sc_essentialsplus.svg);
}

/* Service Charge - Enterprise */
#tblProducts tr td.QuoteProductCell1768 {
  background-image: url(../images/icons/icon_pricing_sc_enterprise.svg);
}

/* TigerPlayer */
#tblProducts tr td.QuoteProductCell1724 {
  background-image: url(../images/icons/icon_pricing_player.svg);
}

/* Mailing Token */
#tblProducts tr td.QuoteProductCell1725 {
  background-image: url(../images/icons/icon_pricing_token_mailer.svg);
}

/* Branding */
#tblProducts tr td.QuoteProductCell1772 {
  background-image: url(../images/icons/icon_pricing_brand.svg);
}

.PTOnly {
  padding: 3px;
  background-color: #f7eaa8 !important;
}

.dynamic-form-required {
  color: var(--errorColour);
}

.libraryListDiv {
    position: relative;
    display: block;
    width: 160px;
    height: 160px;
    margin: 1px;
    background-color: #f8f8f8;
    box-sizing: border-box;
}

.libraryListImg {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='pattern' width='100%25' height='100%25' fill='%23cccccc'%3E%3Cpattern id='pattern-rectangle' x='0' y='0' width='8' height='8' patternUnits='userSpaceOnUse' patternContentUnits='userSpaceOnUse'%3E%3Crect id='pattern-rectangle' x='0' y='0' height='8' width='8' /%3E%3Crect id='pattern-rectangle' x='4' y='0' height='4' width='4' fill='%23ffffff' /%3E%3Crect id='pattern-rectangle' x='0' y='4' height='4' width='4' fill='%23ffffff' /%3E%3C/pattern%3E%3Crect id='rect' x='0' y='0' width='100%25' height='100%25' fill='url(%23pattern-rectangle)' /%3E%3C/svg%3E");
    background-repeat: repeat;
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: auto;
    height: auto;
    cursor: default !important;
}

#RecipientListModeSelect {
  display: grid;
  gap: 2rem;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2,24rem);
}

#RecipientListModeSelect .option {
  border: .1rem solid #c3c3c3;
  border-radius: var(--borderRadius);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
}

#RecipientListModeSelect .option label {
  font-weight: 700;
}

#RecipientListModeSelect .option:has(input:checked) {
  background: #e6f5ff;
  border-color: #b2d3eb;
}

.TableFooterToolbar {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.TableFooterToolbar .PagingRow {
  display: flex;
}

#btnAdhocRemoveAll {
  margin-left: auto;
}

select.draggable {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-top: 1px;
  padding-left: 3px;
  padding-right: 3px;
}

select.draggable option.dragging {
  background-color: #ddeeff;
  opacity: 0.8;
  cursor: grabbing;
}

select.draggable option {
  cursor: grab;
  padding: 10px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select.draggable option:hover {
  background-color: #eaeaea;
}

select.draggable option::before {
  content: '≡';
  font-size: 14px;
  color: #888;
  margin-right: 1rem;
}
