@import 'settings.css';

.pagination {
  display: flex;
  justify-content: center;
}

.pagination-page-info {
  margin: 0 2rem;
  font-weight: bold;
}

.pagination-button {
  padding: 0;
}

.pagination-button:--focus-hover {
  color: var(--tigerOrange);
}

.pagination-select {
  /* Needs a height to make the select element and the input element the same in IE11 */
  height: 3.6rem;
  padding: 0.8rem;
  border: 1px solid #c3c3c3;
  border-radius: var(--borderRadius);
  margin: 0;
  font: inherit;
}

.pagination-total-pages {
  margin-left: -2rem !important;
}

.divPagingInner {
  display: inline-block;
}

.spnPagingLabelPage,
.spnPagingLabelOf {
  margin: 0;
}






#spnPagingLabelPage,
#spnPagingLabelOf {
  margin: 0;
}

#spnCrmPagingLabelPage,
#spnCrmPagingLabelOf {
  margin: 2rem;
}

#divPagingInner {
  display: inline-block;
}
