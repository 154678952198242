.debug {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  max-width: 26rem;
  max-height: 25rem;
  overflow: scroll;
  font-size: 1.3rem;
  color: #a9a9a9;
  background-color: rgb(0 0 0 / 78%);
}

.debug::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.debug {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.debug-white {
  color: #fff;
}

.debug-red {
  color: #ff3f3f;
}

.debug-yellow {
  color: #ffe700;
}

.debug-green {
  color: #16c60c;
}
