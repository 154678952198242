@import 'settings.css';

.issue-summary {
  display: flex;
  position: relative;
  border: var(--border);
  border-radius: var(--borderRadius);
  box-shadow: 0 0.2rem 0.1rem #ececec;
  background: #fff;
}

.issue-summary:not(:first-child) {
  margin-top: 2rem;
}

.issue-quick-link {
  padding: 0.3rem 1rem;
  margin-left: 1.6rem;
  font-size: 1.4rem;
}

.issue-quick-link + .issue-quick-link {
  margin-left: 1rem;
}

.issue-quick-message {
  font-weight: bold;
}

.issue-summary-meta {
  flex-grow: 3;
  padding: 1.2rem 2rem;
}

.issue-top-section {
  display: flex;
  justify-content: space-between;
}

.issue-heading-container {
  flex: 1 0 0;
}

.issue-top-section .button:not(.copy-button) {
  min-width: fit-content;
}

.issue-heading {
  margin: 0;
  font-size: 2.7rem;
  color: #333;
}

.issue-link-container {
  display: flex;
  align-items: center;
}

.issue-link-container > * + * {
  margin-left: 8px;
}

.issue-link {
  display: inline-block;
  max-width: 36rem;
  min-width: 0;
  overflow: hidden;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--tigerBlue);
}

.issue-link:--focus-hover {
  color: var(--tigerBlueHover);
}

.issue-link-button {
  padding: 0.3rem 1rem;
  margin-left: 1.2rem;
  font-size: 1.4rem;
}

.issue-settings {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-left: 16px;
}

.issue-settings > div > * + * {
  margin-left: 8px;
}

.settings-button {
  /* stylelint-disable-next-line declaration-no-important */
  padding-right: 3.5rem !important;
  background-image: url(../images/down-arrow-white.svg);
  background-position: center right 1.1rem;
  background-size: 1.5rem;
  background-repeat: no-repeat;
  transition: transform 0.3s;
  transform: scale(1);
  outline: none;
}

.issue-bottom-section {
  display: flex;
  margin-top: 1.2rem;
}

.issue-botton-section-sub {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
}

.issue-botton-section-sub.mod-player {
  flex: 1 0 auto;
  justify-content: initial;
  width: 33%;
}

.issue-date-container {
  display: flex;
}

.issue-date-container:not(:first-child) {
  margin-top: 1.8rem;
}

.calendar-icon {
  display: inline-block;
  width: 3.3rem;
  height: 3.3rem;
  content: '';
  margin-right: 1.6rem;
  background: url(../images/calendar.svg) center / 33px no-repeat;
}

.tick-icon {
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 1rem;
  background: url(../images/tick-green.svg) center / contain no-repeat;
}

.issue-detail-text {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
  font-size: 2rem;
  color: #737373;
}

.issue-sub-heading {
  margin: 0;
  font-size: 1.9rem;
  font-weight: bold;
}

.issue-sub-text {
  margin: 0.3rem 0 0;
  font-size: 1.6rem;
}

.issue-bold-text {
  margin-right: 0.5rem;
  font-size: 2.6rem;
  font-weight: bold;
  letter-spacing: -0.1rem;
  color: #404040;
}

.issue-detail-text::before,
.issue-date-container::before {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  content: '';
  margin-right: 1.3rem;
  background: center / contain no-repeat;
}

.icon-envelope::before {
  background-image: url(../images/envelope.svg#grey);
}

.icon-people::before {
  background-image: url(../images/people.svg);
}

.icon-pages::before {
  background-image: url(../images/open-magazine.svg);
}

.icon-eye::before {
  background-image: url(../images/bar-chart.svg);
}

.clock-icon::before {
  background-image: url(../images/clock.svg);
}

.playlist-icon::before {
  background-image: url(../images/playlist.svg);
}

.tags-icon::before {
  background-image: url(../images/tags.svg);
}

/* Pop Up */
.issue-pop-up {
  display: none;
  position: absolute;
  top: 115%;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: 0;
  border: var(--border);
  border-radius: var(--borderRadius);
  margin: 0;
  box-shadow: 0 0 1.3rem #c3c3c3;
  background: #fff;
  list-style: none;
  cursor: pointer;
}

.issue-pop-up[aria-hidden='false'] {
  display: block;
}

.issue-pop-up::before {
  position: absolute;
  top: -0.8rem;
  right: 1.2rem;
  z-index: -1;
  content: '';
  border-right: 0.8rem solid transparent;
  border-bottom: 0.8rem solid #fff;
  border-left: 0.8rem solid transparent;
  box-shadow: 0 0 0 #eaeaea;
}

.issue-list-item {
  display: block;
}

.issue-list-item-button {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  border: none;
  font-size: 1.4rem;
  text-align: left;
  color: #000;
  background: transparent;
  cursor: pointer;
}

.issue-list-item-button:--focus-hover {
  color: #000;
  background: #ebebeb;
}

.issue-list-item-button:first-child {
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
}

.issue-list-item-button:last-child {
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
}

.issue-list-item-button.mod-warning {
  color: var(--errorColour);
}

.issue-list-item-button.mod-warning:--focus-hover {
  color: #fff;
  background: var(--errorColour);
}

.issue-image-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.issue-row-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  padding: 0;
  margin: 0;
}

.issue-row-selector:--focus-hover {
  cursor: pointer;
}

.issue-row-selector-checkbox {
  width: 2rem;
  height: 2rem;
  border: 1px solid #c3c3c3;
  background-color: #fff;
  pointer-events: none;
}

.issue-row-selector-checkbox-unchecked {
  background-image: none;
}

.issue-row-selector-checkbox-checked {
  background-image: url(../images/tick-green.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1181px) {
  .issue-summary {
    flex-direction: column;
  }

  .issue-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }
}

@media (min-width: 951px) {
  .issue-top-section {
    padding-bottom: 1.2rem;
    border-bottom: var(--border);
  }
}

@media (max-width: 950px) {
  .issue-settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1.5rem 0 1rem;
  }

  .issue-link-container {
    flex-direction: column;
  }

  .issue-link-button {
    margin-top: 1rem;
    margin-left: 0;
  }

  .publication-link-button {
    display: none;
  }

  .settings-button {
    width: 100%;
  }

  .issue-bottom-section {
    display: none;
  }

  .issue-top-section {
    flex-direction: column;
  }

  .issue-heading {
    text-align: center;
  }

  .issue-link {
    display: block;
    max-width: 100%;
    text-align: center;
  }

  .issue-pop-up {
    top: 138%;
  }
}
