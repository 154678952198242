@import 'settings.css';

.tab-container {
  position: relative;
}

.tab-container::before,
.tab-container::after {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 8rem;
  height: 5.9rem;
  content: '';
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s 0s, visibility 0s 0s;
  visibility: visible;
}

.tab-container::after {
  right: 0;
  background: linear-gradient(270deg, #c5c5c5, rgb(255 255 255 / 0%));
}

.tab-container::before {
  left: 0;
  background: linear-gradient(90deg, #c5c5c5, rgb(255 255 255 / 0%));
}

.tab-container.scroll-started::before,
.tab-container.scroll-ended::after {
  opacity: 0;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  visibility: hidden;
}

.tab-list {
  display: flex;
  position: relative;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  background: #eaeaea;
  list-style: none;
  -webkit-overflow-scrolling: touch;
}

.tab-item {
  flex-shrink: 0;
}

.tab-item-link {
  display: block;
  padding: 2rem;
  border-top: 0.3rem solid transparent;
  border-right: var(--borderDark);
  font-size: 1.4rem;
  font-weight: bold;
  color: #737373;
}

.tab-item-link:--focus-hover {
  color: #353535;
  background-color: #f1f1f1;
  border-top-color: #9fd7ff;
}

.tab-item-link.mod-pt-only {
  background-color: #f5eece;
}

.tab-item-link.mod-pt-only:--focus-hover {
  background-color: #fff8dc;
}

.tab-item-link[disabled='true'] {
  color: #c3c3c3;
  pointer-events: none;
}

.tab-item:first-child .tab-item-link {
  border-left: var(--border);
}

.tab-item-link[aria-selected='true'] {
  color: #353535;
  background-color: #fff;
  border-top-color: #56b8fd;
}

.tab-panel-container {
  padding: 0 1rem;
}

.tab-panel:focus {
  outline: none;
}

.tab-panel[aria-hidden='true'] {
  display: none;
}

/* stylelint-disable-next-line selector-max-type */
.tab-panel > h2:first-child,
.tab-panel > div:first-child {
  margin-top: 1.87rem;
}
