@import 'settings.css';

.file-input {
  position: relative;
  height: 12rem;
  overflow: hidden;
  border: 0.2rem dashed #bfbfbf;
  border-radius: 0.4rem;
  text-align: center;
  color: #838383;
  background: #fff;
}

.file-input-vertical-controller {
  height: 100%;
  transform: translateY(0);
  transition-duration: 0.5s;
}

.file-input-vertical-controller.is-up {
  transform: translateY(-100%);
}

.file-input-section-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.upload-container {
  width: 100%;
}

.upload-container.mod-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.file-input-svg-container {
  position: relative;
}

.file-input-svg {
  display: block;
  position: relative;
  width: 5rem;
  height: 5rem;
  margin: 0 auto 1rem;
}

.file-input-text {
  line-height: 1;
}

.file-input-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  cursor: pointer;
}

.upload-text {
  display: block;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.progress {
  height: 3rem;
  overflow: hidden;
  border-radius: 0.4rem;
  font-size: 0.75rem;
  background-color: #e9ecef;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #9dc272;
  transition-property: transform;
  transform-origin: left center;
}
