@import 'settings.css';

.task-popup {
  min-width: 70rem;
  padding-right: 5rem;
  padding-left: 5rem;
  color: #686868;
}

.task-popup-left {
  display: inline-block;
  margin-top: 1rem;
  margin-right: 10rem;
}

.task-popup-notes {
  margin-bottom: 2rem;
  color: #8c8c8c;
}

.task-popup-notes-header {
  margin-top: 1rem;
  font-weight: bold;
}

.task-popup-property {
  margin-bottom: 1rem;
}

.task-popup-property-name {
  font-weight: bold;
}

.task-popup-property-value {
  margin-top: -0.6rem;
  color: #8c8c8c;
}

.task-popup-right {
  display: inline-block;
}

.task-popup-email-updates-icon {
  display: inline-block;
  position: relative;
  top: 0.5rem;
  width: 1rem;
  height: 1rem;
  padding: 1.1rem;
  border: none;
  background: url(../images/envelope.svg#blue) center / contain no-repeat;
  cursor: help;
}

.task-popup-important-icon {
  position: relative;
  top: 1rem;
  width: 1rem;
  height: 1rem;
  float: right;
  padding: 2rem;
  border: none;
  background: url(../images/email/warning.svg#red) center / contain no-repeat;
  cursor: help;
}

.task-popup-private-icon {
  position: relative;
  top: 0.6rem;
  width: 1rem;
  height: 1rem;
  float: right;
  padding: 2rem;
  border: none;
  background: url(../images/key.svg) center / contain no-repeat;
  cursor: help;
}

.task-popup-completed {
  color: #008000;
}

.task-popup-not-overdue {
  color: var(--tigerOrange);
}

.task-popup-overdue {
  color: var(--tigerRed);
}

.task-path {
  font-size: 1.2rem;
  font-weight: bold;
}

.task-popup-title {
  margin-bottom: -0.6rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.task-popup-header {
  font-weight: bold;
}

.task-popup-deadline {
  margin-bottom: 1rem;
}

.task-popup-email-updates {
  margin-bottom: 2rem;
}

.task-popup-email-updates-text {
  display: inline-block;
  margin-left: 0.6rem;
  font-weight: bold;
  color: #58b;
}

.task-popup-recurrence-details {
  margin-top: 0.2rem;
}

.task-popup-recurrence-details-icon {
  display: inline-block;
  position: relative;
  top: 0.5rem;
  width: 1rem;
  height: 1rem;
  padding: 1.1rem;
  border: none;
  background: url(../images/repeat.svg) center / contain no-repeat;
}

.task-popup-recurrence-details-text {
  display: inline-block;
  margin-left: 0.6rem;
  font-weight: bold;
  color: #58b;
}

.add-task-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.add-task-icon {
  display: block;
  height: 100%;
  background: url(../images/tasks.svg#grey) center / contain no-repeat;
}

.task-popup-form-error {
  margin-bottom: 1rem;
  margin-left: 10rem;
  color: var(--errorColour);
}
