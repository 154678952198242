/* stylelint-disable */
.contextMenuPlugin {
  display: none;
  position: absolute;
  top: 100px;
  left: 100px;
  min-width: 100px;
  padding: 0;
  border: 2px solid #f7f7f7;
  margin: 0;
  font-family: tahoma, arial, sans-serif;
  font-size: 11px;
  background-color: #f7f3f7;
  -webkit-user-select: none;
  list-style-type: none;
  outline: 1px solid #949694;
}

.contextMenuPlugin > li {
  padding: 1px;
  margin: 0;
  background-repeat: no-repeat;
}

.contextMenuPlugin > li > a {
  display: block;
  position: relative;
  padding: 3px 3px 3px 28px;
  margin: 1px;
  text-decoration: none;
  color: ButtonText;
}

.contextMenuPlugin > li > a img {
  position: absolute;
  left: 3px;
  width: 16px;
  height: 16px;
  margin-top: -2px;
}

.contextMenuPlugin > li > a:hover {
  border: 1px solid #fffbff;
  margin: 0;
  background: linear-gradient(to bottom, rgba(239, 239, 255, 0.5) 0%, rgba(223, 223, 255, 0.5) 100%); /* W3C */
  cursor: default;
  outline: 1px solid #b5d3ff;
}

.contextMenuPlugin > li.disabled {
  pointer-events: none;
}

.contextMenuPlugin > li.disabled a {
  color: grey;
}

.contextMenuPlugin > li.disabled > a:hover {
  border: none;
  outline: none;
}

.contextMenuPlugin > li.divider {
  height: 0;
  padding: 0;
  border-top: 1px solid #e7e3e7;
  border-bottom: 1px solid #fff;
  margin: 5px 0 5px 27px;
}

.contextMenuPlugin > .header {
  position: relative;
  padding: 3px;
  color: #fff;
  background: linear-gradient(to bottom, rgba(90, 90, 90, 1) 0%, rgba(20, 20, 20, 1) 100%); /* W3C */
  cursor: default;
}

.contextMenuPlugin > .gutterLine {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 26px;
  z-index: 0;
  width: 0;
  border-right: 1px solid #fff;
  border-left: 1px solid #e7e3e7;
}
