.a11y-interface {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 25rem);
  grid-auto-rows: 1fr;
}

.a11y-interface > div {
  display: flex;
}

.a11y-interface-item {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid #c3c3c3;
  border-radius: var(--borderRadius);
  padding: 2rem;
}

.a11y-interface-item:has(input:checked) {
  background: #e6f5ff;
  border-color: #b2d3eb;
}

.a11y-interface-item:has(input:disabled) {
  background: #f3f3f3;
}

.a11y-interface-item label {
  font-weight: bold;
}

.a11y-interface-item span {
  line-height: 1.4;
}

.a11y-spacer-x-small {
  height: 0.6rem;
}

.a11y-spacer-small {
  height: 1.2rem;
}
