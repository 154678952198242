.kpi-layout {
  display: grid;
  height: 100%;
}

.js-kpidata-app {
  height: 100%;
}

.loading-container {
  width: 100%;
  height: 100%;
  text-align: center;
}

.kpi-container {
  flex-grow: 1;
  height: 32vh;
  overflow-y: scroll;
  padding: 0 2rem 0 0;
}

.clear-kpi-button {
  margin-top: 1rem;
}

.kpi-report-side-bar {
  width: 55rem;
  height: 100%;
  padding: 2rem;
  box-shadow: 0.2rem 0 0.4rem -0.01rem #e2e2e2;
  display: flex;
  background-color: #fff;
  flex-direction: column;
}

.kpi-report-side-bar-kpis {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1.5rem;
}

.kpi-button-container {
  margin-top: auto;
}

.kpi-item {
  display: flex;
  justify-content: space-between;
}

.chart-container {
  grid-column: 2 / span 100;
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.no-chart-container {
  display: block;
  height: 100%;
  opacity: 0.4;
  background: url(../images/PTHead-mono.svg) center / contain no-repeat;
  background-size: 35%;
}

.no-chart-image {
  width: 60%;
  padding-left: 20%;
}

.kpi-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  margin-right: 0.5rem;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.download-kpi-button {
  padding: 1.1rem 2rem !important;
  margin-left: 2rem;
}

.js-kpi-icon {
  display: block;
  height: 100%;
  background: url(../images/kpi-icon.svg) bottom / contain no-repeat;
}
