.design-mode-interface {
  display: grid;
  grid-gap: 1%;
  gap: 1%;
  grid-template-columns: repeat(2, 49.5%);
  grid-auto-rows: 1fr;
}

.design-mode-interface > div {
  display: flex;
}

.design-mode-interface-item {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid #c3c3c3;
  border-radius: var(--borderRadius);
  padding: 2rem;
}

.design-mode-interface-item:has(input[type="radio"]:checked) {
  background: #e6f5ff;
  border-color: #b2d3eb;
}

.design-mode-interface-item:has(input[type="radio"]:disabled) {
  background: #f3f3f3;
}

.design-mode-interface-item label {
  font-weight: bold;
}

.design-mode-interface-item span {
  line-height: 1.4;
}

.design-mode-interface-item li {
  margin-bottom: 0.3rem;
}

.design-mode-spacer-x-small {
  height: 0.6rem;
}

.design-mode-spacer-small {
  height: 1.2rem;
}

.design-mode-dont-ask label {
  font-weight: normal;
  margin-left: 0.5rem;
}

.design-mode-feature-list {
  list-style-type: none;
}

.design-mode-feature-list li::before {
  content: '';
  display: inline-block;
  margin-right: 0.5rem;
}

.design-mode-feature-list li.tick::before {
  width: 1.4rem;
  height: 1.4rem;
  background: url(../images/tick-green.svg) center / contain no-repeat;
}

.design-mode-feature-list li.cross::before {
  width: 1.5rem;
  height: 1.2rem;
  background: url(../images/cross-red.svg) center / contain no-repeat;
  margin-left: -0.1rem;
}
