@import 'settings.css';

.collapsable {
  max-width: 49rem;
  border: 0.1rem solid #c3c3c3;
  border-radius: var(--borderRadius);
  margin-bottom: 4rem;
}

.collapsable-header {
  display: flex;
  padding: 0.7rem 0.6rem;
}

.collapsable-header-title {
  width: 100%;
  padding: 0;
  margin: 0;
}

.collapsable-header-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  font-size: 1.6rem;
  font-weight: bold;
  color: #525252;
  background: none;
  cursor: pointer;
}

.collapsable-header-button-icon {
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  background: url(../images/down-arrow.svg) center / contain no-repeat;
}

.collapsable-header-button-icon.is-open {
  transform: rotate(180deg);
}

.collapsable-header-expand-button {
  margin-left: 1rem;
}

.collapsable-body {
  display: none;
  padding: 1.5rem 0;
}
