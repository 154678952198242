/* stylelint-disable selector-max-type */
@import 'settings.css';

h1 {
  color: var(--tigerOrange);
}

h2 {
  padding: 0.5rem 0;
  margin: 0 0 1.1rem;
  font-size: 2.1rem;
  font-weight: normal;
  color: var(--tigerOrange);
}
