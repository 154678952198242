.app-crm-controls {
  margin-bottom: 1rem;
}

.crm-heading-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  background: #ddd;
}

.crm-heading-container .breadcrumbs {
  margin-bottom: 2rem;
}

.crm-paging-button {
  width: 3.6rem;
  height: 3.6rem;
  border: 1px solid #686868;
  border-radius: 0.4rem;
  margin-left: 0.5rem;
  font-family: cursive;
  font-size: 1.2rem;
  color: #686868;
  background-color: transparent;
  cursor: pointer;
}

.crm-paging-row {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  /* stylelint-disable-next-line declaration-property-unit-whitelist */
  line-height: 24px;
  text-align: center;
}

/* stylelint-disable-next-line selector-max-type */
.crm-paging-row span {
  margin-right: 1.4rem;
  margin-left: 2rem;
}

.crm-search-button {
  width: 10rem;
  height: 3.6rem;
  border: 1px solid #686868;
  border-radius: 0.4rem;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #686868;
  background-color: transparent;
  cursor: pointer;
}

.crm-search-no-results {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.crm-search-options {
  margin-top: 2rem;
}

.crm-strip-button-selected {
  width: 9rem;
  height: 4.7rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #fff;
  background-color: #ef8023;
  cursor: pointer;
}

.crm-strip-button-unselected {
  width: 9rem;
  height: 4.7rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #fff;
  background-color: #686868;
  cursor: pointer;
}

.crm-table-topper {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  box-shadow: 0 -2px 3px -1px #888;
  background: #bdbdbd;
}

/* stylelint-disable-next-line selector-class-pattern */
.crm-table-topper .margin-bottom-10 {
  color: inherit;
}

/* stylelint-disable-next-line selector-max-type */
.crm-table-topper-select-all input {
  width: 9.2rem;
}

.tigerforce-orange {
  position: relative;
  width: 18rem;
  height: 6rem;
  background: url(../images/tigerforce-orange.svg) no-repeat center;
}
