.distribute-contianer {
  display: flex;
}

.distribute-sub-container {
  flex: 1 1 auto;
  width: 50%;
  margin: 0;
}

@media (width < 1150px) {
  .distribute-contianer {
    flex-direction: column;
  }

  .distribute-sub-container {
    width: 100%;
  }
}

.social-link {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  border-radius: 0.5rem;
  margin-right: 3rem;
  background: no-repeat center / 60%;
  transition: background 0.3s;
}

.social-link.facebook {
  background-color: #204384;
  background-image: url(../images/facebook-logo.svg);
}

.social-link.facebook:--focus-hover {
  background-color: #2b7ac7;
}

.social-link.twitter {
  background-color: #000000;
  background-image: url(../images/x.svg);
}

.social-link.twitter:--focus-hover {
  background-color: #555555;
}

.social-link.linkedin {
  background-color: #016699;
  background-image: url(../images/linkedin-logo.svg);
}

.social-link.linkedin:--focus-hover {
  background-color: #1c7eaf;
}
