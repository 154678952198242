@import 'settings.css';

.matrix caption,
.matrix .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#divMatrix {
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 300px);
}

.matrix {
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  isolation: isolate;
}

.matrix td,
.matrix th {
  background-color: #fff;
  padding: 1rem;
  line-height: 1.15;
  vertical-align: middle;
  border: 0;
  box-shadow: inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.08);
}

.matrix tbody th {
  text-align: left;
}

/* Table Header Sticky */
.matrix thead th {
  background-color: #ddd;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Vistor Email - Sticky */
.matrix thead th:first-child,
.matrix tbody tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

/* Table Header - Maintain Priority - Sticky */
.matrix thead th:first-child {
  z-index: 3;
}

.matrix-filters {
  float: right;
}

.matrix-header {
  vertical-align: bottom;
  /* font-weight: bold; */
}

.matrix-contact-header {
  vertical-align: bottom;
}

.matrix-contact-header-href {
  display: inline-block;
  white-space: wrap;
  width: 18ch;
  font-weight: bold;
}

.matrix .cell-content-center {
  vertical-align: middle;
  text-align: center;
}

.matrix .matrix-expired {
  background-color: #fdfaf3;
}

.matrix .matrix-unearned {
  background-color: #fbf4f3;
}

.matrix tbody .matrix-cell-highlight:not(.matrix-unearned):not(.matrix-expired) {
  background-color: #fafafa;
}

.matrix-cell-completion {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.matrix-cell-completion-total {
  text-align: right;
  font-weight: bold;
}

.matrix .matrix-empty-table-cell {
  text-align: left;
  font-weight: bold;
  height: 6rem;
}

.matrix .matrix-empty-table-cell > span {
  position: sticky;
  left: 0;
  padding-left: 1rem;
}

.statusIcon {
  cursor: help;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.iconCheck {
  mask: url('data:image/svg+xml,%3Csvg%20%20xmlns=%22http://www.w3.org/2000/svg%22%20%20width=%2224%22%20%20height=%2224%22%20%20viewBox=%220%200%2024%2024%22%20%20fill=%22none%22%20%20stroke=%22currentColor%22%20%20stroke-width=%221.5%22%20%20stroke-linecap=%22round%22%20%20stroke-linejoin=%22round%22%20%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-check%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M5%2012l5%205l10%20-10%22%20/%3E%3C/svg%3E');
  background-color: #087b56;
}

.iconX {
  mask: url('data:image/svg+xml,%3Csvg%20%20xmlns=%22http://www.w3.org/2000/svg%22%20%20width=%2224%22%20%20height=%2224%22%20%20viewBox=%220%200%2024%2024%22%20%20fill=%22none%22%20%20stroke=%22currentColor%22%20%20stroke-width=%221.5%22%20%20stroke-linecap=%22round%22%20%20stroke-linejoin=%22round%22%20%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-x%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M18%206l-12%2012%22%20/%3E%3Cpath%20d=%22M6%206l12%2012%22%20/%3E%3C/svg%3E');
  background-color: #bb291f;
}

.iconAlertTriangle {
  mask: url('data:image/svg+xml,%3Csvg%20%20xmlns=%22http://www.w3.org/2000/svg%22%20%20width=%2224%22%20%20height=%2224%22%20%20viewBox=%220%200%2024%2024%22%20%20fill=%22none%22%20%20stroke=%22currentColor%22%20%20stroke-width=%221.5%22%20%20stroke-linecap=%22round%22%20%20stroke-linejoin=%22round%22%20%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-alert-triangle%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M12%209v4%22%20/%3E%3Cpath%20d=%22M10.363%203.591l-8.106%2013.534a1.914%201.914%200%200%200%201.636%202.871h16.214a1.914%201.914%200%200%200%201.636%20-2.87l-8.106%20-13.536a1.914%201.914%200%200%200%20-3.274%200z%22%20/%3E%3Cpath%20d=%22M12%2016h.01%22%20/%3E%3C/svg%3E');
  background-color: #795b0f;
}
