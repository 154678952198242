.flash-message {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  position: relative;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.flash-message + .flash-message {
  margin-top: 2rem;
}

.flash-message.mod-split {
  justify-content: space-between;
}

.flash-message.mod-success {
  border-color: #bdead6;
  color: #0f5132;
  background-color: #c7f7c0;
}

.flash-message.mod-info {
  border-color: var(--infoDark);
  background-color: var(--infoLight);
}

.flash-message.mod-warning {
  border-color: var(--warningBorderColour);
  color: var(--warningTextColour);
  background-color: var(--warningBGColour);
}

.flash-message.mod-error {
  border-color: var(--warningBorderColour);
  color: var(--warningTextColour);
  background-color: var(--warningBGColour);
}

.flash-message-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  background: url(../images/flash-warning.svg#yellow) center / contain no-repeat;
}

@media (width < 700px) {
  .flash-message-icon {
    display: none;
  }
}

.flash-message-text {
  color: #633c25;
}

.flash-message-text > * {
  max-width: 80rem;
}

.flash-message-close-button {
  height: 16px;
  width: 20px;
  padding: 0 !important;
  border: none;
  background: url(../images/cross.svg#darkyellow) no-repeat center / contain;
}
