.ptui-pill {
  align-self: center;

  display: inline-flex;
  padding: 2px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 24px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 157.143% */

  cursor: default;
}

.ptui-alert {
  align-self: stretch;

  display: flex;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 4px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 157.143% */

  cursor: default;
}

.ptui-alert svg {
  margin-top: 2px;
}

.ptui-pill.success {
  color: rgba(21, 115, 69, 1);
  border: 1px solid rgba(21, 115, 69, 0.2);
  background: rgba(21, 115, 69, 0.05);
}

.ptui-pill.warning {
  color: rgba(121, 91, 15, 1);
  border: 1px solid rgba(215, 161, 12, 0.2);
  background: rgba(215, 161, 12, 0.05);
}

.ptui-pill.danger {
  color: rgba(187, 41, 31, 1);
  border: 1px solid rgba(187, 41, 31, 0.2);
  background: rgba(187, 41, 31, 0.05);
}

.ptui-alert.success {
  color: rgba(21, 115, 69, 1);
  border: 1px solid rgba(21, 115, 69, 0.2);
  border-left: 4px solid rgba(21, 115, 69, 0.8);
  background: rgba(21, 115, 69, 0.05);
}

.ptui-alert.warning {
  color: rgba(121, 91, 15, 1);
  border: 1px solid rgba(215, 161, 12, 0.2);
  border-left: 4px solid rgba(215, 161, 12, 0.8);
  background: rgba(215, 161, 12, 0.05);
}

.ptui-alert.danger {
  color: rgba(187, 41, 31, 1);
  border: 1px solid rgba(187, 41, 31, 0.2);
  border-left: 4px solid rgba(187, 41, 31, 0.8);
  background: rgba(187, 41, 31, 0.05);
}

.course-document-details summary > span {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.course-document-details summary + div {
  margin-top: 0.5rem;
}

.course-document-details .issue-container {
  border: 1px solid #d6d6d6;
  border-radius: 0.4rem;
  margin: 2rem 0;
  box-shadow: 0 0.1rem 0.6rem #d4d4d4;
}

.course-document-details .status {
  margin-top: 0.5rem;
}
