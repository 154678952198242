/* THIS FILE HAS HAD LINTING DISABLED BECAUSE IT'S CAUSING
THE LINTING TO FAIL. NEXT PERSON TO WORK ON IT PLEASE FIX */
/* stylelint-disable */

.sales-dashboard table {
  line-height: 2.5rem;
}

.sales-dashboard td .cell-sub-heading {
  margin-right: 1rem;
  font-weight: 700;
}

.sales-dashboard td .cell-sub-text {

}

.sales-dashboard td .highlight {
  padding: 0.2rem 0.5rem;
  border: none;
  font-weight: bold;
  color: white;
}

.sales-dashboard td .red {
  background-color: #d2222d;
}

.sales-dashboard td .grey {
  background-color: #5f5c58;
}

.sales-dashboard td .green {
  background-color: #238823;
}

.sales-dashboard td .orange {
  background-color: #ffbf00;
}

.sales-dashboard table .issue-settings {
  display: block;
}

.sales-dashboard table .tertiary-button {
  display: block;
  width: 8rem;
  float: right;
  padding: 0.7rem 1rem;
  border: var(--borderDark);
  border-radius: var(--borderRadius);
  text-align: center;
  color: #616161;
  background-color: #fff;
  cursor: pointer;
}

.sales-dashboard table .tertiary-button:--focus-hover {
  background-color: #e8e8e8;
}

.sales-dashboard table a .cell-sub-heading {
  color: #525252;
}

.sales-dashboard table a .cell-sub-text {
  color: white;
}

.sales-dashboard table .issue-pop-up {
  width: auto;
}

.colour-key-green {
  background: #dff0d8;
}

.colour-key-amber {
  background: var(--pageTigerOnlyBG);
}

.colour-key-red {
  background: #ffdfdf;
}
