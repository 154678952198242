@import 'settings.css';

.issue-outer-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem 3rem;
}

.issue-container {
  width: 25rem;
  background-color: #fff;
}

.issue-container.mod-has-padding {
  border: 1px solid #d6d6d6;
  border-radius: var(--borderRadius);
  margin: 2rem;
  box-shadow: 0 0.1rem 0.6rem #d4d4d4;
}

/* stylelint-disable */
@media (min-width: 900px) {
  .issue-container.is-3-columns {
    width: calc(33% - 4rem);
  }

  .issue-container.is-4-columns {
    width: calc(25% - 4rem);
  }

  .issue-container.is-5-columns {
    width: calc(20% - 4rem);
  }
}

@media (max-width: 899px) {
  .issue-container.is-3-columns {
    width: calc(50% - 4rem);
  }

  .issue-container.is-4-columns {
    width: calc(33% - 4rem);
  }

  .issue-container.is-5-columns {
    width: calc(20% - 4rem);
  }
}

@media (max-width: 599px) {
  .issue-container.is-3-columns {
    width: calc(100% - 4rem);
  }

  .issue-container.is-4-columns {
    width: calc(50% - 4rem);
  }

  .issue-container.is-5-columns {
    width: calc(33% - 4rem);
  }
}

@media (max-width: 530px) {
  .issue-container.is-4-columns {
    width: calc(100% - 4rem);
  }

  .issue-container.is-5-columns {
    width: calc(50% - 4rem);
  }
}
/* stylelint-enable */

.issue-cover {
  display: block;
  position: relative;
  padding: 0.1rem;
  background-color: #d6d6d6;
}

.issue-cover-link {
  display: block;
  height: 25rem;
  overflow: hidden;
  margin: 0;
  text-indent: 100%;
  white-space: nowrap;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.issue-cover-link.mod-fill-width {
  background-position: top;
  background-size: cover;
}

/* These selectors target ID's on the dev, staging and production
   systems, take care whilst changing them */
/* stylelint-disable selector-class-pattern */
/* Blank Design ID */
.issue-cover-link.mod-issue-9999999999,
/* Dev ID's */
.issue-cover-link.mod-issue-4058,
.issue-cover-link.mod-issue-4044,
/* Staging ID's */
.issue-cover-link.mod-issue-4076,
.issue-cover-link.mod-issue-4085,
/* Production ID's */
.issue-cover-link.mod-issue-44415,
.issue-cover-link.mod-issue-44416 {
  background: center / 100% no-repeat;
}

.issue-cover-link.mod-issue-4058,
.issue-cover-link.mod-issue-4076,
.issue-cover-link.mod-issue-44416 {
  background-image: url(../images/create/icon-presentation.svg);
}

.issue-cover-link.mod-issue-4044,
.issue-cover-link.mod-issue-4085,
.issue-cover-link.mod-issue-44415 {
  background-image: url(../images/create/icon-ebook.svg);
}

.issue-cover-link.mod-issue-9999999999 {
  background-image: url(../images/create/icon-blank.svg);
}
/* stylelint-enable selector-class-pattern */

/* stylelint-disable selector-max-class */
.issue-container.is-small .issue-cover-link {
  height: 20rem;
}

.issue-container.is-medium .issue-cover-link {
  height: 25rem;
}

.issue-container.is-large .issue-cover-link {
  height: 48rem;
}
/* stylelint-enable  */

.issue-cover-tiger-genius {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  overflow: hidden;
  border-right: none;
  text-indent: 100%;
  white-space: nowrap;
  background-color: var(--tigerOrange);
}

.issue-icons-container {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--tigerOrange);
}

.issue-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-right: solid 1px #ffb068;
  text-indent: 100%;
  white-space: nowrap;
  background-position: center;
  background-repeat: no-repeat;
}

.issue-icon:last-child {
  border-right: none;
}

.issue-icon.template-icon {
  background-image: url(../images/images/pttemplateover.png);
}

.issue-icon.pagelibrary-icon {
  background-image: url(../images/images/ptPageLibrary.png);
}

.issue-icon.component-icon {
  background-image: url(../images/images/ptComponent.png);
}


.issue-icon.tigerkit-icon {
  background-image: url(../images/images/ptTigerKitOver.png);
}

.issue-icon.from-tiger-kit-icon {
  background-image: url(../images/images/ptFromTigerKitOver.png);
}

.issue-icon.tiger-genuis-icon {
  background-image: url(../images/images/ptTigerGeniusOver.png);
}

.issue-icon-word {
  height: 3rem;
  padding: 0 1rem;
  border-right: solid 1px #ffb068;
  line-height: 1.9;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.issue-icon-word.is-green {
  border-right: solid 1px #33c11f;
  background-color: #33c11f;
}

.issue-icon-word.is-failed {
  border-right: solid 1px var(--errorColour);
  background-color: var(--errorColour);
}

.issue-update {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: #666 url(../images/images/ptrefresh.png) no-repeat center;
}

.issue-update:--focus-hover {
  background-color: #f5811c;
}

.issue-meta {
  margin: 1rem;
}

.issue-meta-link,
.issue-meta-title {
  display: block;
  overflow: hidden;
  font-size: 1.8rem;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.issue-meta-link:--focus-hover {
  color: var(--tigerOrange);
}

.issue-meta-text {
  display: block;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #9c9c9c;
}

.issue-links-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.issue-links-container.large-padding {
  margin: 2rem 1rem;
}

.issue-icon-action:--focus-hover {
  background-color: var(--tigerOrangeHover);
}

.issue-tooltip {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  width: 15rem;
  padding: 1.2rem;
  border-radius: var(--borderRadius);
  margin-top: 1rem;
  margin-right: 1rem;
  box-shadow: 0 0 0.7rem #808080;
  line-height: 1.5;
  text-align: center;
  background: #fff;
}

.issue-tooltip[aria-hidden='true'] {
  display: none;
}

.issue-tooltip::before {
  position: absolute;
  top: -0.8rem;
  right: 1.2rem;
  content: '';
  border-right: 0.8rem solid transparent;
  border-bottom: 0.8rem solid #fff;
  border-left: 0.8rem solid transparent;
}

.no-records-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  border: solid 0.1rem #ccc;
  border-radius: var(--borderRadius);
  margin: 2rem;
  text-align: center;
}
