:root {
  --borderColour: #eaeaea;
}

.user-roles-table-list {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.user-roles-table-list-truncation {
  display: block;
  padding-top: 6px;
  font-weight: bold;
  cursor: help;
}

.roles {
  padding: 1rem 0;
  background-color: #fff;
}

.role {
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  padding: 16px;
  border-bottom: 1px solid var(--borderColour);
}

.role-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 35px;
}

.role-collection + .role-collection {
  margin-top: 8rem;
}

.role-label {
  font-weight: bold;
}

.role-indenter {
  position: relative;
  margin-left: 75px;
}

.role-indenter::before {
  display: block;
  position: absolute;
  left: -35px;
  width: 1px;
  height: 100%;
  content: '';
  border-left: 1px solid var(--borderColour);
}

.role-description {
  display: block;
  margin-top: 5px;
}

.role-spacer {
  overflow: hidden;
}

.role-indenter .role::before {
  display: block;
  position: absolute;
  top: 100%;
  left: -35px;
  width: 38px;
  height: 1px;
  content: '';
  border-top: 1px solid var(--borderColour);
}

.role-subrole {
  display: flex;
}

.role-subrole-container {
  display: flex;
}

.role-subrole-container:last-child .role-spacer::after {
  display: none;
  border-color: transparent;
}

.role-help-text {
  line-height: 1.6;
}
