@import 'settings.css';

.filter-container {
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
}

.filter-container > *,
.filter-summary > * {
  margin-bottom: 1rem;
}

.filter-button {
  display: flex;
  padding: 0.7rem 1rem;
  border: var(--borderDark);
  border-radius: var(--borderRadius);
  color: #616161;
  background-color: #fff;
  cursor: pointer;
  margin-left: 2rem;
}

.filter-button:--focus-hover {
  background-color: #e8e8e8;
}

.controls-svg {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 1rem;
  pointer-events: none;
}

.filter-pop-up-container {
  display: none;
  position: absolute;
  top: 4.5rem;
  right: 0;
  min-width: 25rem;
  padding: 4rem 2rem 2rem 2rem;
  z-index: 1;
  background: #fdfdfd;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.04), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
}

.filter-pop-up-container[aria-hidden='false'] {
  display: block;
}

.filter-pop-up-controls {
  max-height: 47rem;
  overflow-y: auto;
  column-rule: 1px solid #ececec;
  column-gap: 4rem;
}

.filter-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.warning-button {
  padding: 0;
  color: #c10808;
}

.cancel-button {
  margin-right: 1.5rem;
}

.filter-container-clear-button {
  margin-left: 1rem;
}

.filter-button-icon {
  display: block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  background: url(../images/filter.svg#grey) center / contain no-repeat;
  pointer-events: none;
}

.filter-button-icon.mod-active {
  background-image: url(../images/filter.svg#blue);
}

.filter-close-button {
  align-self: stretch;
  width: 4.8rem;
  min-height: 4rem;
  overflow: hidden;
  padding: 0;
  border: none;
  text-indent: 100%;
  white-space: nowrap;

  /* Use pixels for IE11 compat */
  background: url(../images/cross.svg#grey) center / 10px no-repeat;
  cursor: pointer;
  outline: none;

  position: absolute;
  right: 0;
  top: 0.2rem;
}

/* stylelint-disable-next-line */
.form-element label {
  margin-left: 0.5rem;
}

.is-filter-sets {
  padding-bottom: 2rem;
  border-bottom: 1px solid #ececec;
}

.default-filter-set {
  font-weight: bold;
  background: #f9f5e0;
}

.filter-set-button-container {
  margin-top: 1.3rem;
}

.apply-filter-set {
  margin: 1rem 1rem 0 0;
}
