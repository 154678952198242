.tooltip {
  z-index: 9;
  opacity: 0;
  padding: 1.1rem 1.5rem;
  border-radius: var(--borderRadius);
  margin-left: 1rem;
  box-shadow: 0.2rem 0.2rem 0.4rem #6d6d6d;
  font-size: 1.5rem;
  color: #fff;
  background: #272727;
  transition: visibility 0.1s linear, opacity 0.1s linear;
}

.tooltip[aria-hidden='false'] {
  opacity: 1;
}

.tooltip-arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: -0.5rem;
  border: 0.5rem solid #272727;
  margin-right: 0;
  margin-left: 0;
  transform: translateY(-50%) rotate(45deg);
}
