/* stylelint-disable property-no-vendor-prefix */
@import 'settings.css';

.trail-heading {
  margin-bottom: 3rem;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  color: #585858;
}

.admin-container {
  max-width: 90rem;
  margin: 0 auto;
}

.admin-input-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
}

.admin-input {
  display: flex;
  flex-direction: column;
  width: 15rem;
}

.admin-number-quantity {
  width: 100%;
  border: none;
  font-size: 7rem;
  font-weight: bold;
  text-align: center;
  color: #585858;
  background: none;
  -moz-appearance: textfield;
}

.admin-number-input::-webkit-outer-spin-button,
.admin-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.admin-number-button {
  position: relative;
  width: 4rem;
  border: none;
  margin: 0 0.5rem;
  background: transparent url(../images/left-angle-bracket.svg#blue) center / 100% no-repeat;
  cursor: pointer;
}

.admin-number-button.is-flipped {
  transform: rotate(180deg);
}

.price-section-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 700px) {
  .price-section-container {
    flex-direction: column;
    align-items: center;
  }
}

.price-section {
  display: flex;
  flex-direction: column;
  width: 30rem;
  text-align: center;
  background: #fff;
}

.price-section + .price-section {
  margin-left: 8rem;
}

@media (max-width: 700px) {
  .price-section + .price-section {
    margin-top: 2rem;
    margin-left: 0;
  }
}

.price-section-heading {
  font-size: 3.2rem;
  color: var(--black);
}

/* stylelint-disable declaration-no-important */
.price-section-button {
  padding: 1.3rem 1rem !important;
  border: none;
  font-size: 2.2rem !important;
  font-weight: bold !important;
  background: #4eb266 !important;
  cursor: pointer;
  transition: background 0.2s;
  text-transform: uppercase !important;
}

.price-section-button:--focus-hover {
  background: #6ad483 !important;
}
/* stylelint-enable declaration-no-important */

.price-section-info-text {
  margin: 2rem 0 4rem;
  font-size: 1.7rem;
  line-height: 1.3;
}

.price-section-price {
  margin-bottom: 2.5rem;
}

.price-section-currency {
  position: relative;
  top: -3.7rem;
  font-size: 4.6rem;
  font-weight: bold;
  color: var(--black);
}

.price-section-total {
  font-size: 9.6rem;
  font-weight: bold;
  letter-spacing: -0.3rem;
  color: var(--black);
}

.price-section-period {
  display: block;
  font-size: 1.6rem;
  color: #8e8e8e;
}

.price-currency-information {
  margin-top: 1.6rem;
  text-align: center;
  color: #8c8c8c;
}

.price-input-hidden {
  display: none;
}
