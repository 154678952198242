.tiger-popper {
  display: flex;
  position: fixed;
  top: 35rem;
  left: -0.2rem;
  z-index: 6;
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  box-shadow: 0 0 0.4rem #7f7f7f;
  background: var(--tigerOrange);
  transform: rotate(270deg);
  transform-origin: left top;
}

@media (max-width: 1399px) {
  .tiger-popper {
    display: none;
  }
}

.tiger-popper-button {
  padding: 1.1rem 1.6rem;
  border: none;
  border-radius: var(--borderRadius);
  font-size: 1.8rem;
  color: #fff;
  background: transparent;
  cursor: pointer;
}

.help-button.mod-link {
  padding: 0.6rem;
}

.tiger-popper-button.mod-button-icon {
  display: flex;
  padding: 0.9rem;
}

.intercom-icon {
  fill: #fff;
}

.modal-links-svg .intercom-icon {
  fill: #000;
}

.tiger-popper-notification {
  transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.3);
  transform: scale(0);
  transform-origin: 17px 3px;
}

@media all and (-ms-high-contrast: none) {
  /* IE 11 doesn't like to scale SVGs, so just disable the notification circle in IE11 */
  /* stylelint-disable-next-line */
  *::-ms-backdrop,
  .tiger-popper-notification {
    display: none;
  }
}

.tiger-popper-notification.mod-show {
  transform: scale(1);
}

.tiger-popper-hide {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  margin: -1px;
  clip-path: inset(50%);
  clip: rect(1px, 1px, 1px, 1px);
}

.tiger-popper-button:--focus-hover {
  background: var(--tigerOrangeHover);
}

.tiger-popper-button:not(:last-child) {
  border-right: 1px solid var(--tigerOrangeHover);
}

.tiger-popper-play-icon,
.tiger-popper-chat-icon,
.tiger-popper-help-icon {
  pointer-events: none;
}

.tiger-popper-doc-icon.mod-rotate,
.tiger-popper-chat-icon.mod-rotate,
.tiger-popper-play-icon.mod-rotate {
  transform: rotate(90deg);
}

/* MODAL */
.modal-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.modal-links-container {
  display: flex;
  width: 90%;
  margin: 0 auto;
}

.modal-video-container + .modal-links-container {
  margin-top: 5rem;
}

.modal-video-element {
  display: block;
  width: 100%;
  max-width: 77rem;
  max-height: 100%;
}

.video-modal-title {
  width: 0;
  height: 0;
}

.video-modal-close-button {
  align-self: stretch;
  position: absolute;
  top: -4rem;
  right: 0;
  width: 4rem;
  min-height: 4rem;
  overflow: hidden;
  padding: 0;
  border: none;
  text-indent: 100%;
  white-space: nowrap;

  /* Use pixels for IE11 compat */
  background: url(../images/cross-white.svg) center / 20px no-repeat;
  cursor: pointer;
}

@media (height >= 700px) {
  .modal-help {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25rem;
  }
}

.modal-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  text-align: center;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  list-style-type: none;
}

.modal-links:not(:first-child) {
  margin-left: 3.5rem;
}

.modal-links:--focus-hover {
  color: var(--tigerOrangeHover);
}

@media (width >= 850px) {
  .modal-links {
    width: 25%;
  }
}

@media (width <= 849px) {
  .modal-links-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal-links {
    width: 30rem;
  }

  .modal-links:not(:first-child) {
    margin-top: 2rem;
    margin-left: 0;
  }
}

@media (width < 430px) {
  .modal-links {
    width: 100%;
  }
}

.modal-links-svg {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.2rem;
  pointer-events: none;
}

.modal-links-heading {
  padding: 0;
  margin: 0;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.2;
  pointer-events: none;
}

.modal-links-heading-text {
  font-size: 1.5rem;
  pointer-events: none;
}

.modal-links-text {
  max-width: 100%;
}
