.timeline {
  width: 100%;
  border-spacing: 0 4rem;
}

.timeline .info {
  line-height: 1.6;
}

.timeline-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  margin-right: 0.5rem;
  background: transparent;
  cursor: pointer;
  outline: none;
  flex-shrink: 0;
}

.timeline-icon {
  display: block;
  height: 100%;
  background: url(../images/timeline.svg) bottom / contain no-repeat;
}

.timeline .edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  padding: 2rem;
  border: none;
  border-radius: 0.4rem;
  background: url(../images/edit.svg#grey) center / 55% no-repeat;
  cursor: pointer;
}

.note-attachment {
  display: inline-block;
  padding: 1rem;
  border-radius: 0.4rem;
  margin-right: 1rem;
  background: #eaeaea;
}

.note-attachment:--focus-hover {
  background-color: #0000000d;
}

.note-attachment-heading {
  display: block;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.timeline-main-heading {
  margin: 2rem 0 0;
  font-size: 2.6rem;
  color: #000;
}

.timeline-main-heading-link {
  font-size: inherit;
  color: #0972e0;
}

.timeline-heading {
  display: block;
  margin-top: 1.6rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.timeline-arrow {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  background: transparent url(../images/down-arrow-white.svg) center / contain no-repeat;
  pointer-events: none;
  transition: transform 0.3s ease;
}

.timeline-arrow.mod-open {
  transform: rotate(180deg);
}

.pop-heading {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}

.pop-section {
  display: none;
  position: relative;
  padding: 2rem;
  border: 1px solid #d8d8d8;
  border-radius: 0.4rem;
  margin-top: 1rem;
  background: #fff;
}

.pop-section.mod-show {
  display: block;
}

.progress {
  display: block;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 8px;
}

.timeline-loading,
.timeline-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30rem;
}

.timeline-loading-svg {
  width: 5rem;
  height: 5rem;
  background: url(../images/timeline-loading.svg) center / contain no-repeat;
}

.timeline-loading-text {
  margin-top: 1.3rem;
  font-size: 1.6rem;
}

.timeline-empty-text {
  font-size: 1.6rem;
}

.timeline-item {
  display: grid;
  grid-template-columns: 16rem auto;
  position: relative;
  width: 100%;
  padding: 2rem;
  border: 0.1rem solid #e0e0e0;
  border-radius: 0.4rem;
  margin-top: 2rem;
  background: #fff;
  gap: 4rem;
}

.timeline-item.mod-sticky {
  background-color: #e2f0ff;
}

.timeline-item + .timeline-item {
  margin-top: 2rem;
}

.timeline-item-meta-type-joint {
  display: flex;
}

.timeline-item-meta-type-joint-complete {
  /* stylelint-disable-next-line */
  border-radius: 0 4rem 4rem 0 !important;
  background: #000;
  border-width: 0.1rem;
}

/* stylelint-disable-next-line selector-max-class */
.timeline-item-meta-type.mod-task.mod-complete {
  border-radius: 4rem 0 0 4rem;
}

.timeline-item-meta-action {
  display: block;
  margin: 5px 0;
  text-align: center;
}

.timeline-item-expand-button {
  padding: 1.2rem 2rem;
  border: 1px solid;
  border-radius: 0.4rem;
  background: none;
  cursor: pointer;
}

.timeline-item-error-text {
  margin-top: 1.3rem;
  font-weight: bold;
  color: #d30000;
}

.timeline-item-meta-type {
  display: inline-block;
  width: 100%;
  padding: 0.5rem 0.4rem;
  border-radius: 2.4rem;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.timeline-item-meta-type.mod-note {
  border-color: #0772e0;
  background: #0772e0;
}

.timeline-item-meta-type.mod-tracking {
  border-color: #ff5722;
  background: #ff5722;
}

.timeline-item-meta-type.mod-task {
  flex: 0 2 auto;
  border-color: #29c103;
  background: #29c103;
}

.timeline-item-meta-type.mod-sticky {
  background: #333;
}

.timeline-item-meta-type.mod-sub-type {
  padding: 0.4rem 1.4rem;
  border: 0.1rem solid #333;
  color: #333;
}

.timeline-item-meta-type.mod-email {
  background: var(--tigerOrange);
}

.timeline-item-meta-important-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.timeline-item-meta-important {
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-left: 0.2rem;
  background: url(../images/email/warning.svg#red) center / contain no-repeat;
}

.timeline-item-meta-important-text {
  margin-left: 0.6rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #cd5c5c;
  text-transform: uppercase;
}

.timeline-item-meta-user {
  margin: 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.timeline-item-meta-time {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.timeline-item-meta-user.mod-italic,
.timeline-item-meta-time.mod-italic {
  font-style: italic;
}

.timeline-item-iframe {
  width: 100%;
  height: 30rem;
  border: none;
}

.timeline-item-title {
  margin: 0;
  font-size: 2rem;
  color: #000;
}

.timeline-item-text {
  line-height: 1.6;
}

.timeline-item-controls {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.timeline-item-controls-item {
  width: 2rem;
  height: 2rem;
  padding: 2rem;
  background: transparent center / 55% no-repeat;
}

.timeline-item-controls-button {
  padding: 2rem;
  border: none;
  background-image: url(../images/pin-note.svg);
  cursor: pointer;
}

.timeline-item-link {
  display: block;
  background-image: url(../images/edit.svg#grey);
}

.timeline-item-content-attachment-title {
  margin-top: 3rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.timeline-item-content-attachment-container {
  margin: 2rem 0 1rem;
}

.timeline-item-content-attachment-link {
  padding: 1rem 2rem;
  border: 0.1rem solid #c5c5c5;
  border-radius: 0.4rem;
  text-decoration: none;
  color: #025bb7;
}

.timeline-item-content-attachment-link + .timeline-item-content-attachment-link {
  margin-left: 1rem;
}

.timeline-item:first-child.mod-green {
  transition: background 1s ease;
}

.timeline-item.mod-animate {
  background-color: #d4efd4;
  animation-name: backgroundcolor;
  animation-duration: 4s;
  animation-iteration-count: 1;
}

@keyframes backgroundcolor {
  0% {
    background-color: rgb(0 0 0 / 0%);
  }

  50% {
    background-color: #d4efd4;
  }

  100% {
    background-color: rgb(0 0 0 / 0%);
  }
}

.save-note-spinner {
  display: none;
  width: 3rem;
  height: 3rem;
  margin-left: 1.5rem;
  background: url(../images/spinner.svg) center / contain no-repeat;
}

.save-note-spinner.is-shown {
  display: block;
}

/* BREADCRUMBS */
.timeline-breadcrumbs-list-item-button {
  padding: 0;
  border: none;
  font-size: inherit;
  color: #0972e0;
  background: transparent;
  cursor: pointer;
}

.timeline-sub-item-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.7rem;
  border: 1px solid #cacaca;
  border-radius: 0.4rem;
  background: #fff;
  cursor: pointer;
}

.timeline-sub-item-toggle-span {
  margin-left: 1rem;
}

.timeline-crumbs-filter {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
}

.timeline-item-subitem-button {
  padding: 0.7rem 1.4rem;
  border: none;
  border-radius: 3.4rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  background: #dadada;
  cursor: pointer;
}

.timeline-item-subitem-button:--focus-hover {
  color: #2271c3;
}

.js-email-dropzone,
.js-task-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 0.4rem;
  background-color: rgb(151 151 151 / 21%);
  transition: 0.3s ease backdrop-filter;
  backdrop-filter: blur(0.2rem);
  visibility: hidden;
}

.js-email-dropzone.mod-show,
.js-task-dropzone.mod-show {
  opacity: 1;
  visibility: visible;
}

.dropzone-text {
  font-size: 4.8rem;
  color: #191a1b;
  pointer-events: none;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

.js-timeline-clear-email-button {
  display: none;
  padding: 0.58rem 1.5rem;
  margin-left: 1rem;
}

.js-timeline-clear-email-button.mod-show {
  display: inline-block;
}

/** TRACKING **/

.tracking-item {
  position: relative;
  padding: 3rem 0;
}

.tracking-item::before {
  display: block;
  position: absolute;
  left: 30px;
  width: 0.1rem;
  height: calc(100% - 10rem);
  content: '';
  border-left: 1px solid #bfbfbf;
}

.tracking-item-inner {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4rem;
}

.tracking-item-inner + .tracking-item-inner {
  margin-top: 2rem;
}

.tracking-item-site-link {
  display: inline-block;
  padding-left: 2rem;
  font-size: 1.9rem;
  font-weight: bold;
}

.tracking-item-right {
  padding-top: 0.6rem;
}

.tracking-item-big-time {
  position: relative;
  padding: 0.5rem 0;
  font-size: 2.3rem;
  font-weight: bold;
  background: #fff;
}

.tracking-item-site {
  display: inline-block;
  min-width: 9rem;
  padding: 0.5rem 1.6rem;
  border-radius: 5rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.tracking-item-site.mod-view {
  background: #6661ff;
}

.tracking-item-site.mod-website {
  background: #00a2bf;
}

.tracking-item-event-details {
  margin-top: 1rem;
}

.tracking-item-event-details-time {
  display: inline-block;
  margin-right: 1.3rem;
}

.tracking-item-event-details-type {
  font-size: 1.6rem;
  font-weight: bold;
}

.tracking-item-event-details-type-arrow {
  margin: 0 0.8rem;
}

.property-table {
  width: 100%;
  text-align: left;
}
