/* stylelint-disable selector-max-id, selector-max-specificity,
  selector-id-pattern, selector-class-pattern */
@import './settings.css';

.create-new-issue-container {
  position: relative;
}

.create-new-issue-options-container {
  display: none;
  position: absolute;
  top: calc(100% + 1.6rem);
  z-index: 1;
  border-radius: var(--borderRadius);
  box-shadow: 0 1px 6px #a0a0a0;
  background: #fff;
}

@media (max-width: 601px) {
  .create-new-issue-options-container {
    width: 100%;
  }
}

.create-new-issue-options-container::before {
  display: block;
  position: absolute;
  top: -1.6rem;
  left: 5.5rem;
  width: 0;
  height: 0;
  content: '';
  border: 0.8rem solid transparent;
  border-bottom-color: #d2d2d2;
}

.create-new-issue-options-container[aria-hidden='false'] {
  display: block;
}

#liIssues {
  margin-right: 2rem;
}

.button-spacer:not(:first-child) {
  margin-left: 1rem;
}

.publication-link-container {
  display: flex;
  align-items: center;
  position: relative;
}

.publication-link {
  display: inline-block;
  max-width: 42rem;
  overflow: hidden;
  font-size: 1.7rem;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.extra-margin {
  margin-bottom: 1.3rem;
}

.plupload_header {
  display: none;
}

.plupload_container {
  padding: 0;
}

.plupload_filelist_footer {
  display: none;
}

.top-heading-section {
  display: flex;
  justify-content: space-between;
}

.top-heading-section-right {
  display: flex;
  align-items: center;
}

@media (max-width: 981px) {
  .top-heading-section {
    flex-direction: column;
  }

  #pnlOnlineMagLink {
    margin-bottom: 1rem;
  }
}

.copy-section {
  display: inline-flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  gap: 1rem;
}

.copy-link {
  display: inline-block;
  max-width: 50rem;
  overflow: hidden;
  font-size: 1.7rem;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-url-input {
  border-radius: var(--borderRadius) 0 0 var(--borderRadius);
}

.copy-tooltip {
  display: none;
  position: absolute;
  top: -115%;
  right: 0;
  padding: 0.7rem 1.6rem;
  border-radius: var(--borderRadius);
  color: #fff;
  background: #69d051;
}

.copy-tooltip.mod-visible {
  display: block;
}

.copy-tooltip.mod-issue-position {
  top: -165%;
  right: -25%;
}

.copy-tooltip::after {
  display: block;
  position: absolute;
  left: 70%;
  width: 1rem;
  height: 1rem;
  content: '';
  background: inherit;
  transform: rotate(45deg);
}

.custom-expiry-date {
  display: none;
  margin-top: 1rem;
}

.manage-sub-tables {
  width: 98%;
  max-height: 25rem;
  overflow-y: auto;
  margin: 1rem 0 1rem 1rem;
}
