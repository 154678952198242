.dynamic-form-description {
  opacity: 0.8;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.4;
  color: inherit;
}

.dynamic-form-message {
  padding: 2rem;
  border: 1px solid;
  border-radius: 0.4rem;
  margin-bottom: 2rem;
}

.dynamic-form-message.mod-hidden {
  display: none;
}

.dynamic-form-message.mod-error {
  border-color: #fbdada;
  color: #b00;
  background: #fdf0f0;
  outline-color: #b00;
}

.dynamic-form-message.mod-success {
  border-color: #91e2af;
  color: #0c802f;
  background: #f0fdf6;
  outline-color: #0c802f;
}

/* stylelint-disable-next-line selector-max-class */
.mod-dark .dynamic-form-message.mod-success {
  border-color: #02af53;
  color: #fff;
  background: #04af53;
  outline-color: #04af53;
}

.dynamic-form-heading {
  margin-bottom: 2rem;
}

.form-element + .dynamic-form-heading {
  margin-top: 4rem;
}

.dynamic-form-message-title {
  margin: 0;
  font-size: 1.7rem;
  line-height: 1.4;
}

.dynamic-form-title {
  margin: 0 0 0.3rem;
  font-size: 2.4rem;
  line-height: 1.4;
}

.js-form .form-element-label {
  margin-left: 0;
}

.dynamic-form-element-error {
  display: block;
  margin: 0.4rem 0 0;
  font-size: 1.5rem;
  color: var(--errorColour);
}

.dynamic-form-list {
  padding-left: 1.6rem;
  margin-bottom: 0;
}

.dynamic-form-error {
  font-size: 1.4rem;
  color: inherit;
}

.dynamic-form-error-link {
  font-weight: bold;
  color: inherit;
}

.dynamic-form-error-link:--focus-hover {
  text-decoration: underline;
  color: inherit;
}

.dynamic-form-button {
  margin-bottom: 1.8rem;
}

.dynamic-form-message-link {
  display: inline-block;
  margin-top: 2rem;
}
