.js-chart-widget {
  margin-bottom: 7px;
}

.chart-colour-container {
  display: flex;
  justify-content: space-between;
  max-width: 170px;
  padding: 5px;
  border-radius: 4px;
  background: #d6d6d6;
}

.chart-colour-container + .chart-colour-container {
  margin-top: 8px;
}

.sp-replacer {
  border: solid 1px #c3c3c3;
  border-radius: 4px;
  background: #eee;
}

/* stylelint-disable declaration-no-important */
.sp-preview {
  width: 26px !important;
  height: 26px !important;
}
/* stylelint-enable declaration-no-important */

.chart-colour-container .sp-preview {
  border: none;
  border-radius: 4px;
}