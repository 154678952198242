.details {
  border: 0.1rem solid #9e9e9e;
  border-radius: 0.4rem;
}

.details + .details {
  margin-top: 1.5rem;
}

.summary {
  padding: 1.6rem 1rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.summary:focus {
  border-radius: 0.4rem;
  box-shadow: 0 0 0 0.1rem #a1cdff;
  background: #d3eaff;
}

[open] > .summary:focus {
  border-radius: 0.4rem 0.4rem 0 0;
}
