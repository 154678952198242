.temp-link-select {
  float: right;
  margin-top: 2rem;
}

#divQRModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center
}

#divQrCode:not(:empty) {
  width: 24rem;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  box-shadow: inset 0 0 4px rgba(0,0,0,0.25);
}

#divQrCode:empty,
#divQrCode:empty ~ .copy-section #qrQrCopy,
#divQrCode:empty ~ .copy-section #divQrDownload {
  display: none;
}

#divQrCode svg {
  image-rendering: pixelated;
  height: auto;
}

#qrQrCopy {
  position: relative;
}
